import {
  Insight,
  InsightValue,
  InsightValue as InsightValueField,
  InsightValueType,
  RiskLevel,
  SelectedRiskFactor,
} from "@planckdata/api"
import { createMap } from "@planckdata/typescript-utils"
import startCase from "lodash/startCase"
import InsightName from "./insightNames"
import { useCallback, useState } from "react"

export type InsightWithRiskFactor = Insight & Partial<SelectedRiskFactor>

export function convertValueToList(editedValue: string, insightName: string, delimiter = "|"): Insight["values"] {
  return editedValue.split(delimiter).map<InsightValueField>((val) =>
    buildInsight({
      name: insightName,
      expected_value_type: InsightValueType.StringAttribute,
      value: val.trim(),
    }),
  )
}

/**
 * Get user edited insight value or Planck's supplied value
 */
export function getInsightValue(insight?: Insight): string | null {
  return insight?.feedback?.fixed_value ?? insight?.value ?? null
}

export function valueExistInInsights(insights: Insight[], value: string): boolean {
  return insights.some((insight) =>
    insight.feedback
      ? insight.feedback.fixed_value.toLowerCase() === value.toLowerCase()
      : insight.value.toLowerCase() === value.toLowerCase(),
  )
}

const DEFAULT_INSIGHT_VALUES: Insight = {
  display_name: "",
  evidence: null,
  feedback: null,
  final_decision_feedback: null,
  possible_values: "",
  type: null,
  user_editable: false,
  package: "",
  confidence: null,
  expected_value_type: InsightValueType.StringAttribute,
  name: "",
  value: "N/A",
  values: [],
  raw_value: {
    expectedValueType: InsightValueType.StringAttribute,
    value: null,
    confidence: null,
  },
}

export function buildInsight<T extends Insight>(params: Partial<T>): T {
  return { ...DEFAULT_INSIGHT_VALUES, ...params } as T
}

// BUCKETS
export interface BucketInfo<T> {
  from: number
  to: number
  key: T
}

function getBucket<T = any>(buckets: Array<BucketInfo<T>>, value: number | null): BucketInfo<T> | null {
  if (value == null) {
    value = 0
  }
  for (let i = 0; i < buckets.length; i++) {
    const bucket = buckets[i]
    if (value >= bucket.from && value <= bucket.to) {
      return bucket
    }
  }

  return null
}

export enum ConfidenceBucketKey {
  Low = "low",
  MediumLow = "medium_low",
  Medium = "medium",
  High = "high",
}

export type ConfidenceBucket = BucketInfo<ConfidenceBucketKey>

export const CONFIDENCE_BUCKETS: Array<ConfidenceBucket> = [
  { from: 0.5, to: 0.65, key: ConfidenceBucketKey.Low },
  { from: 0.66, to: 0.8, key: ConfidenceBucketKey.MediumLow },
  { from: 0.81, to: 0.9, key: ConfidenceBucketKey.Medium },
  { from: 0.91, to: 1, key: ConfidenceBucketKey.High },
]

export function getConfidenceBucket(confidence: number): ConfidenceBucket | null {
  return getBucket(CONFIDENCE_BUCKETS, confidence)
}

export enum NaicsBucketKey {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export type NaicsBucket = BucketInfo<NaicsBucketKey>

export const NAICS_BUCKETS: Array<NaicsBucket> = [
  { from: 0, to: 0.34, key: NaicsBucketKey.Low },
  { from: 0.35, to: 0.68, key: NaicsBucketKey.Medium },
  { from: 0.69, to: 1, key: NaicsBucketKey.High },
]

export function getNaicsBucket(value: number | null): NaicsBucket | null {
  return getBucket(NAICS_BUCKETS, value)
}

export function isEmptyInsightValue(value: string | null | undefined): boolean {
  const trimmedValue = value?.trim().toLowerCase()
  return !trimmedValue || trimmedValue === "n/a"
}

export function isConfidenceInsight(insight: InsightValueField): boolean {
  return insight.name.toLowerCase().includes("confidence")
}

export function getInsightsWithRiskFactors(
  insights: Array<Insight>,
  riskFactors: Array<SelectedRiskFactor> | undefined,
): Array<InsightWithRiskFactor> {
  const riskFactorsMap = createMap(riskFactors ?? [], "name")
  return insights.map((insight) => ({ ...riskFactorsMap[insight.name], ...insight }))
}

interface ItemSortData {
  displayName: string
  isEmpty: boolean
  riskFactor: boolean
  riskLevel: RiskLevel
}

/**
 * Get risk level for insight
 *
 * - High risk - value is equal to high_risk_value
 * - Low risk - value is NOT equal to high_risk_value
 * - No risk - value is not defined
 *
 * For insights with no high_risk_value defined, the value is considered high risk if it is equal
 * to "true" and low risk if it is equal to "false"
 */
export function getRiskLevel(insight: InsightWithRiskFactor): RiskLevel {
  const insightValue = getInsightValue(insight)
  if (isEmptyInsightValue(insightValue)) {
    return RiskLevel.none
  }
  const highRiskValue = insight.high_risk_value
  const isHighRisk = insightValue?.toLowerCase() === highRiskValue?.toLowerCase()
  if (highRiskValue && isHighRisk) {
    return RiskLevel.high
  }
  if (highRiskValue && !isHighRisk) {
    return RiskLevel.low
  }
  return RiskLevel.none
}

/**
 * Same as getRiskLevel() but returns High & Low for True & False values
 */
export function getDisplayRiskLevel(insight: InsightWithRiskFactor): RiskLevel {
  const riskLevel = getRiskLevel(insight)
  if (riskLevel !== "none") {
    return riskLevel
  }

  const value = getInsightValue(insight)?.toLowerCase()
  if (value) {
    if (value === "true") {
      return "high"
    } else if (value === "false") {
      return "low"
    }
  }

  return riskLevel
}

function getRiskFactorSortData(insight: InsightWithRiskFactor): ItemSortData {
  const value = insight.feedback?.fixed_value ?? insight.value
  const riskFactor = insight.high_risk_value !== undefined
  const isEmpty = isEmptyInsightValue(value)
  const riskLevel = getRiskLevel(insight)

  return {
    displayName: insight.display_name,
    isEmpty,
    riskFactor,
    riskLevel,
  }
}

export function getRiskFactorInsights(
  riskFactors: Array<SelectedRiskFactor> | undefined,
  insights: Array<Insight> | undefined,
): Array<InsightWithRiskFactor> {
  if (!riskFactors) {
    return []
  }
  const insightsMap = createMap(insights ?? [], "name")
  return riskFactors.reduce<InsightWithRiskFactor[]>((acc, rf) => {
    const insight = insightsMap[rf.name]
    if (insight) {
      acc.push({ ...rf, ...insight })
    }
    return acc
  }, [])
}

export function sortInsightsByRiskFirst(insights: Array<InsightWithRiskFactor>): Array<InsightWithRiskFactor> {
  return insights.slice().sort((aItem, bItem) => {
    const a = getRiskFactorSortData(aItem)
    const b = getRiskFactorSortData(bItem)

    // High risk
    if (a.riskLevel === "high" && b.riskLevel !== "high") return -1
    if (a.riskLevel !== "high" && b.riskLevel === "high") return 1

    // Low risk
    if (a.riskLevel === "low" && b.riskLevel !== "low") return -1
    if (a.riskLevel !== "low" && b.riskLevel === "low") return 1

    // No risk defined
    if (a.riskFactor == null && !a.isEmpty && b.riskFactor != null) return -1
    if (a.riskFactor != null && b.riskFactor == null && !b.isEmpty) return 1

    // Empty insights
    if (a.isEmpty && !b.isEmpty) return 1
    if (!a.isEmpty && b.isEmpty) return -1

    // A-Z
    return a.displayName.localeCompare(b.displayName)
  })
}

export interface InsightSortOrder {
  sortBy: "name" | "value" | "package" | "confidence"
  sortOrder: "asc" | "desc"
}

const DEFAULT_SORT: InsightSortOrder = { sortBy: "value", sortOrder: "asc" }

function strCompare(a: string, b: string): number {
  return cleanStr(a).localeCompare(b, undefined, {
    sensitivity: "base",
  })
}

export function cleanStr(string?: string): string {
  return string?.toLowerCase()?.trim() ?? ""
}

export function isSearchMatch(term: string, insight: Insight): boolean {
  return [insight.display_name, insight.description].some((value) => cleanStr(value).includes(cleanStr(term)))
}

export interface InsightSortOptions {
  initialSort?: InsightSortOrder
}

export function useInsightSort(
  options?: InsightSortOptions,
): [InsightSortOrder, (updates: Partial<InsightSortOrder>) => void] {
  const [sort, setSort] = useState<InsightSortOrder>(options?.initialSort ?? DEFAULT_SORT)
  const updateSort: (updates: Partial<InsightSortOrder>) => void = useCallback(
    (updates) => {
      const useInitialSort = !updates.sortBy && !updates.sortOrder
      setSort(useInitialSort ? options?.initialSort ?? DEFAULT_SORT : (prev) => ({ ...prev, ...updates }))
    },
    [options],
  )

  return [sort, updateSort]
}

function sortByConfidence(a: Insight, b: Insight): number {
  const aIsEmpty = isEmptyInsightValue(a.value)
  const bIsEmpty = isEmptyInsightValue(b.value)
  if (aIsEmpty) {
    return bIsEmpty ? 0 : -1
  } else if (bIsEmpty) {
    return 1
  }

  return (a.confidence?.v1.value ?? 0) - (b.confidence?.v1.value ?? 0)
}

export function insightSortFn(sortBy: InsightSortOrder): (a: Insight, b: Insight) => number {
  function sorted(): (a: Insight, b: Insight) => number {
    if (!sortBy.sortBy) {
      return () => 0
    }
    switch (sortBy.sortBy) {
      case "name":
        return (a, b) => {
          return strCompare(a.display_name || a.name, b.display_name || b.name)
        }
      case "package":
        return (a, b) => {
          return strCompare(a.package, b.package)
        }
      case "value":
        return (a, b) => {
          return getSortRank(a) - getSortRank(b)
        }
      case "confidence":
        return (a, b) => {
          return sortByConfidence(a, b)
        }
      default:
        throw new Error("bad sort type")
    }
  }
  if (sortBy.sortOrder === "desc") {
    return (a, b) => sorted()(a, b) * -1
  }

  return sorted()
}

interface SortRanks {
  highRisk: number
  lowRisk: number
  trueValue: number
  likely: number
  falseValue: number
  other: number
  NA: number
}

const DEFAULT_SORT_MAP: SortRanks = {
  highRisk: 1,
  trueValue: 2,
  likely: 3,
  other: 4,
  lowRisk: 5,
  falseValue: 6,
  NA: 7,
}

const RISK_FACTORS_SORT_MAP: SortRanks = {
  highRisk: 1,
  NA: 2,
  lowRisk: 3,
  trueValue: 4,
  likely: 4,
  falseValue: 4,
  other: 4,
}

function getSortRank(insight: InsightWithRiskFactor, sortMap: SortRanks = DEFAULT_SORT_MAP) {
  const insightRiskLevel = getRiskLevel(insight)

  if (insightRiskLevel === RiskLevel.high) {
    return sortMap.highRisk
  }
  if (insightRiskLevel === RiskLevel.low) {
    return sortMap.lowRisk
  }
  if (isLikelyResult(insight)) {
    return sortMap.likely
  }
  if (insight.value.toLowerCase() === "true") {
    return sortMap.trueValue
  }
  if (insight.value.toLowerCase() === "false") {
    return sortMap.falseValue
  }
  if (isEmptyInsightValue(getInsightValue(insight))) {
    return sortMap.NA
  }
  return sortMap.other
}

export function sortInsights<T extends Insight>(insights: T[], options: InsightSortOptions = {}): T[] {
  return [...insights].sort(insightSortFn({ ...DEFAULT_SORT, ...options }))
}

export function sortRiskFactors(insights: InsightWithRiskFactor[]): InsightWithRiskFactor[] {
  return [...insights].sort((a, b) => getSortRank(a, RISK_FACTORS_SORT_MAP) - getSortRank(b, RISK_FACTORS_SORT_MAP))
}

const insightsNamesOverride: Partial<Record<InsightName, string>> = {
  [InsightName.SlipFallRBTIndex]: "Slip and Fall Index",
}

export function parseInsightName(insightName: InsightName): string {
  return insightsNamesOverride[insightName] || startCase(insightName)
}

export const SocialLinkType = {
  facebook: "facebook",
  instagram: "instagram",
  website: "website",
  yelp: "yelp",
  google: "google",
  foursquare: "foursquare",
  yellowpages: "yellowpages",
  buildzoom: "buildzoom",
} as const
export type SocialLinkType = (typeof SocialLinkType)[keyof typeof SocialLinkType]

export interface EvidenceData {
  text: string
  links: string[]
}

export function getInsightEvidence(insight: InsightValue): EvidenceData {
  return {
    text: insight.confidence?.v1.evidence?.text?.[0] ?? "",
    links: insight.confidence?.v1.evidence?.links ?? [],
  }
}

function isValue(insightValue: string | null, value: string, caseSensitive = false): boolean {
  const normalizedInsightValue = caseSensitive ? insightValue : insightValue?.toLowerCase()
  const normalizedCompareValue = caseSensitive ? value : value.toLowerCase()
  return normalizedInsightValue === normalizedCompareValue
}

export function filterInsightsByValue(
  insights: Array<Insight> | undefined,
  value: string,
  caseSensitive = false,
): Insight[] {
  return (
    insights?.filter((insightName) => {
      const insightValue = getInsightValue(insightName)
      return isValue(insightValue, value, caseSensitive)
    }) ?? []
  )
}

const TRUE_VALUE = "True"

export function filterTrueValues(insights: Array<Insight> | undefined): Insight[] {
  return filterInsightsByValue(insights, TRUE_VALUE)
}

export function isBooleanInsight(insight: Insight): boolean {
  return insight.expected_value_type === InsightValueType.BooleanAttribute
}

export function isFalsyValue(insight: Insight): boolean {
  const val = getInsightValue(insight)
  return !val || isEmptyInsightValue(val) || (isBooleanInsight(insight) && val.toLowerCase() !== "true")
}

export function isTruthyValue(insight: Insight): boolean {
  const val = getInsightValue(insight)
  return !!val && !isEmptyInsightValue(val) && (!isBooleanInsight(insight) || val.toLowerCase() !== "false")
}

export function isLikelyResult(insight: Insight): boolean {
  return insight.confidence?.v1.result === "Likely"
}
