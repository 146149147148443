import React, { useEffect } from "react"
import { Trans, useTranslation } from "i18n"
import { CaseAPI, GetExportedFileRequest } from "@planckdata/api"
import { AppDialog, DialogProps, colors } from "@planckdata/react-components"
import { DownloadIcon } from "@planckdata/react-components/components/icons"
import { openLink } from "@planckdata/typescript-utils/url_utils"
import { DownloadText } from "../../../pages/common/CustomComponents"
import useTrackEvents from "hooks/track-events.hook"

export interface DownloadBusinessesButtonProps extends DialogProps {
  fileName: string
  onClose: () => void
}

export const DownloadBatchDialog: React.FC<DownloadBusinessesButtonProps> = ({ open, ...props }) => {
  const { t } = useTranslation()
  const { export: events } = useTrackEvents()

  const download = React.useCallback(async () => {
    const request: GetExportedFileRequest = {
      file_name: props.fileName,
    }
    events.downloadExportedFile(props.fileName)

    const downloadLink = await CaseAPI.getExportedFile(request)
    openLink(downloadLink)
  }, [props.fileName, events])

  useEffect(() => {
    if (open) {
      download()
    }
  }, [download, open])

  const getDescription = React.useCallback(() => {
    return (
      <Trans
        i18nKey="my_businesses_page.download_file.description"
        components={{ download: <DownloadText onClick={download} bold={true} /> }}
      >
        text
      </Trans>
    )
  }, [download])

  const title = t("my_businesses_page.download_file.title")
  const body = getDescription()
  const icon = <DownloadIcon fontSize="inherit" />
  const iconColor = colors.primary

  return (
    <AppDialog
      dialogTitle={title}
      icon={icon}
      iconColor={iconColor}
      content={body}
      open={open}
      onClose={props.onClose}
    />
  )
}
