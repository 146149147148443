import React, { PropsWithChildren, useMemo } from "react"
import { AddressAttributeValue, ExtendedCaseData, Insight } from "@planckdata/api"
import InsightName from "utils/insightNames"
import { US_STATES } from "utils/us-states"
import { ConvertKeysToCamelCase } from "utils/types/type_utils"

export type ContextInsightsMap = Record<InsightName, Insight<any>> | undefined
export interface CaseDataContext {
  value: ExtendedCaseData | undefined
  insightsMap: ContextInsightsMap
  update<K extends keyof ExtendedCaseData>(key: K, data: React.SetStateAction<ExtendedCaseData[K]>): void
}
const CaseDataContext = React.createContext<CaseDataContext>({
  value: undefined,
  insightsMap: undefined,
  update: () => void 0,
})

export const CaseDataProvider: React.FC<PropsWithChildren<Omit<CaseDataContext, "classification">>> = (props) => {
  const { value, update, insightsMap, ...rest } = props
  return <CaseDataContext.Provider value={{ value, insightsMap, update }} {...rest} />
}

export function useCaseData(): CaseDataContext {
  return React.useContext(CaseDataContext)
}

export function useMainAddressInfo(): ConvertKeysToCamelCase<AddressAttributeValue> | null {
  const { insightsMap } = useCaseData()

  const address = useMemo(
    () => insightsMap?.[InsightName.MainAddress]?.raw_value as unknown as ConvertKeysToCamelCase<AddressAttributeValue>,
    [insightsMap],
  )

  return address
}

export function useStateCode(): string | null {
  const address = useMainAddressInfo()

  const stateCode = useMemo(() => {
    if (!address?.state) return null
    const state = US_STATES.find((s) => s.value === address.state)
    // displayName: "State (STATE_CODE)"
    const stateCode = state?.code ?? address.state
    return stateCode
  }, [address?.state])

  return stateCode
}
