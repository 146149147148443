export enum BusinessClassification {
  CONSTRUCTION = "construction",
  MANUFACTURING = "manufacturing",
  RBNT = "rbnt",
  RBNT_JAPAN = "rbnt_japan",
  RETAIL = "retail",
  STAFFING = "staffing",
  AUTOSERVICES = "autoservices",
  HEALTHCARE = "healthcare",
  LANDSCAPING = "landscaping",
  PERSONAL_CARE = "personalcare",
}

/**
 * This map includes the name of the section for each of the classifications as it appears in mapping-configuration
 **/
export const CLASSIFICATION_TO_PACKAGE_MAP: Record<BusinessClassification, string> = {
  construction: "Construction",
  manufacturing: "Manufacturing",
  rbnt: "RBNT",
  rbnt_japan: "Restaurants Japan",
  retail: "Retail",
  staffing: "Staffing",
  autoservices: "Auto Services",
  healthcare: "Healthcare",
  landscaping: "Landscaping",
  personalcare: "Personal Care",
}
