import React from "react"
import clsx from "clsx"
import SideMenuHeader from "./SideMenuHeader"
import { Divider, Drawer, DrawerProps, List, makeStyles } from "@planckdata/react-components"
import { MenuItems } from "./MenuItems"
import { SideMenuItemProps } from "./SideMenuItem"
import { useTranslation } from "i18n"
import { useUser } from "user-context"
import SideMenuProfileBox from "./SideMenuProfileBox"
import SideMenuItems from "./SideMenuItems"
import { observer } from "mobx-react-lite"

export const DRAWER_CLOSED_WIDTH = 64
const DEFAULT_DRAWER_OPEN_WIDTH = 265

const LocaleDrawerWidths = {
  "en-US": DEFAULT_DRAWER_OPEN_WIDTH,
  "de-DE": 310,
}

const drawerOpenWidth = (locale: string): number =>
  LocaleDrawerWidths[locale as keyof typeof LocaleDrawerWidths] ?? DEFAULT_DRAWER_OPEN_WIDTH

export function useDrawerOpenWidth(): number {
  const { i18n } = useTranslation()
  return drawerOpenWidth(i18n.language)
}
export interface SideMenuProps extends DrawerProps {
  items?: SideMenuItemProps[]
  onToggle?(open: boolean): void
  onTogglePin?(pinned: boolean): void
}

export interface SideMenuStyleProps extends SideMenuProps {
  width: number
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    willChange: "width",
    width: (props: SideMenuStyleProps) => props.width,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: (props: SideMenuStyleProps) => props.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: DRAWER_CLOSED_WIDTH,
  },
  spacer: {
    flex: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}))

function getCachedPinStatus(): boolean {
  return window.localStorage.getItem("sidebar_pinned") === "true"
}

function setCachedPinStatus(state: boolean): void {
  window.localStorage.setItem("sidebar_pinned", String(state))
}

export const SideMenu: React.FC<SideMenuProps> = observer(({ items: _items, onToggle, onTogglePin, ...props }) => {
  const drawerOpenWidth = useDrawerOpenWidth()
  const classes = useStyles({ ...props, width: drawerOpenWidth })
  const cachedPinned = getCachedPinStatus()
  const hoverPersistence = React.useRef(false)
  const [open, setOpen] = React.useState(cachedPinned || hoverPersistence.current)
  const [pinned, setPinned] = React.useState(cachedPinned)
  const user = useUser()

  const items = (_items ?? MenuItems).filter(
    (item) =>
      [undefined, true].includes(item.visible as any) ||
      (typeof item.visible === "function" && item.visible(user.current)),
  )

  React.useEffect(() => {
    onToggle?.(open)
  }, [onToggle, open])

  React.useEffect(() => {
    onTogglePin?.(pinned)
    setCachedPinStatus(pinned)
    window.dispatchEvent(new Event("SidebarToggle"))
  }, [onTogglePin, pinned])

  const handleDrawerOpen = React.useCallback(() => {
    setOpen(true)
    hoverPersistence.current = true
  }, [])

  const handleDrawerClose = React.useCallback(() => {
    setOpen(pinned)
    hoverPersistence.current = false
  }, [pinned])

  const handleDrawerPinned = React.useCallback(() => {
    setPinned(!pinned)
  }, [pinned])

  const handleProfileBoxClose = React.useCallback(() => {
    if (open && !pinned) {
      setOpen(false)
    }
  }, [open, pinned])

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      {...props}
    >
      <SideMenuHeader isOpen={open} isPinned={pinned} drawerPinnedHandler={handleDrawerPinned} />

      <Divider />
      <List className={classes.list}>
        <SideMenuItems items={items} open={open} />
        <div className={classes.spacer} />
        {user.current ? <SideMenuProfileBox user={user.current} open={open} onClose={handleProfileBoxClose} /> : null}
      </List>
    </Drawer>
  )
})

export default SideMenu
