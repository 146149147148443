import { LoginFormProps, useLogger } from "@planckdata/react-components"
import { useUser } from "user-context"
import React from "react"
import { useHistory } from "react-router"
import { Routes } from "routes"
import { mixpanel } from "analytics"
import useTrackEvents from "hooks/track-events.hook"
import { takeQueryStringValue } from "@planckdata/typescript-utils"
import { getHomeRoute } from "utils"
import { ENV } from "../../../env"

export type UseLoginResponse = Omit<LoginFormProps, "mode">

export interface LoginOptions {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  showResetPassword: boolean
}

export function useLogin({ loading, setLoading, showResetPassword }: LoginOptions): UseLoginResponse {
  const user = useUser()
  const logger = useLogger()
  const { login: events } = useTrackEvents()

  const returnTo = takeQueryStringValue("returnTo")

  React.useEffect(() => {
    user
      .initializeClient()
      .catch((e) => logger.logException(e, "User init error"))
      .then(() => {
        setLoading(false)
        if (user.isLoggedIn) {
          if (ENV.IS_PLUS_PLATFORM && !user.current?.internal && !user.current?.flags?.isPlusUser) {
            logger.warn("Non-PLUS user logged in to PLUS Platform")
          }
          mixpanelIdentify(user.current!.username)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loginProps: UseLoginResponse = {
    onLogin: async (details) => {
      events.loginFormSubmitted()
      const loginParams = {
        ...details,
        redirectUri: returnTo ? window.location.href : undefined,
      }
      await user.requestLogin(loginParams)
      mixpanelIdentify(details.email)
      events.successfulLogin()
      setLoading(false)
    },
    onRegister: async () => console.warn("Not implemented: Register"),
    onResetPassword: async (details) => {
      events.resetPasswordClicked()
      const res = await user.requestPasswordChange(details)
      setLoading(false)
      return res
    },
    isLoggedIn: user.isLoggedIn,
    loading: loading,
    routes: {
      home: getHomeRoute(user.current),
      login: Routes.Login,
      passwordReset: Routes.ForgotPassword,
      register: Routes.Register,
    },
    showResetPassword,
  }
  return loginProps
}

function mixpanelIdentify(username: string): void {
  const distinctId = mixpanel.get_distinct_id()
  if (distinctId !== username) {
    mixpanel.alias(distinctId, username)
  }
  mixpanel.identify(username)
}

export function usePageView(): void {
  const { trackPageView } = useTrackEvents()
  const history = useHistory()

  React.useEffect(() => {
    function handleLocationChanged(event: any, action: string) {
      if (action !== "REPLACE" || event.state?.shouldTrack === true) {
        trackPageView()
      }
    }

    trackPageView()
    return history.listen(handleLocationChanged)
  }, [history, trackPageView])
}
