import React from "react"
import { colors, CustomMUIComponentProps, makeStyles, Tooltip, TooltipProps, Theme } from "@planckdata/react-components"
import clsx from "clsx"

export interface InsightsMappingTooltipStyleProps {
  tooltipFontSize?: number | string
}

export interface InsightsMappingTooltipProps
  extends CustomMUIComponentProps<TooltipProps, keyof typeof useStyles>,
    InsightsMappingTooltipStyleProps {
  //
}

const useStyles = makeStyles<Theme, InsightsMappingTooltipStyleProps>(() => ({
  root: {
    //
  },
  tooltip: {
    minWidth: 430,
    background: colors.boxBg,
    fontStyle: "italic",
    borderRadius: "10px",
    color: colors.disable,
    padding: "10px",
    fontSize: (p) => p.tooltipFontSize ?? 16,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  tooltipArrow: {
    color: colors.boxBg,
  },
}))

export const InsightsMappingTooltip: React.FC<InsightsMappingTooltipProps> = ({ className, children, ...props }) => {
  const classes = useStyles(props)

  return (
    <Tooltip
      className={clsx(className, classes.root)}
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

InsightsMappingTooltip.defaultProps = {
  placement: "bottom-start",
}

export default InsightsMappingTooltip
