import React from "react"
import { useTranslation } from "i18n"
import {
  alpha,
  Chip,
  ChipProps,
  colors,
  CustomMUIComponentProps,
  makeStyles,
  Typography,
  Theme,
} from "@planckdata/react-components"
import MoreHoriz from "@planckdata/react-components/mui/icons/MoreHoriz"
import clsx from "clsx"
import { ENV } from "env"

enum ChipDisplay {
  Interested = "interested",
  Mapped = "mapped",
  Upcoming = "upcoming",
  Unmapped = "unmapped",
}

export interface InsightMappingStateChipStyleProps {
  /**
   * If true, will apply the "Interested" state as an intermediate state, and ignore the `value`
   * (similar to `intermediate` in `<Checkbox />`)
   */
  interested?: boolean
  loading?: boolean
  fullWidth?: boolean
  upcoming?: boolean
}

export interface InsightMappingStateChipProps
  extends Omit<CustomMUIComponentProps<ChipProps, keyof typeof useStyles>, "value">,
    InsightMappingStateChipStyleProps {
  value: boolean
}

const useStyles = makeStyles<Theme, InsightMappingStateChipStyleProps>(() => ({
  root: {
    cursor: "inherit",
  },
  mapped: {
    backgroundColor: alpha(colors.success, 0.1),
    color: colors.success,
  },
  mappedLabel: {
    fontWeight: 600,
    fontSize: 14,
  },
  unmapped: {
    backgroundColor: alpha(colors.foregroundAltText, 0.1),
    color: colors.foregroundAltText,
  },
  unmappedLabel: {
    fontWeight: 400,
    fontSize: 12,
  },
  interested: {
    backgroundColor: alpha(colors.warning, 0.1),
    color: colors.warningText,
  },
  interestedLabel: {
    fontWeight: 600,
    fontSize: 12,
  },
  loader: {
    marginTop: 7,
  },
}))

export const InsightMappingStateChip: React.FC<InsightMappingStateChipProps> = (props) => {
  const classes = useStyles(props)
  const { className, value, interested, upcoming, loading, ...rest } = props
  const { t } = useTranslation()

  function getChipDisplay() {
    if (interested) return ChipDisplay.Interested
    if (value) return ChipDisplay.Mapped
    if (upcoming) return ChipDisplay.Upcoming
    return ChipDisplay.Unmapped
  }

  const chipDisplay = getChipDisplay()
  const positive = chipDisplay === ChipDisplay.Mapped
  const partial = chipDisplay === ChipDisplay.Interested
  const negative = chipDisplay === ChipDisplay.Unmapped || chipDisplay === ChipDisplay.Upcoming

  const plus = ENV.IS_PLUS_PLATFORM ? "plus." : ""

  return (
    <Chip
      {...rest}
      className={clsx(className, classes.root, {
        [classes.mapped]: positive,
        [classes.interested]: partial,
        [classes.unmapped]: negative,
      })}
      label={
        <Typography
          className={clsx({
            [classes.mappedLabel]: positive,
            [classes.interestedLabel]: partial,
            [classes.unmappedLabel]: negative,
          })}
        >
          {loading ? <MoreHoriz className={classes.loader} /> : t("insights_mapping.state_chip." + plus + chipDisplay)}
        </Typography>
      }
    />
  )
}

export default InsightMappingStateChip
