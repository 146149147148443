import React, { PropsWithChildren } from "react"
import { makeStyles, Theme, InformationPopover } from "@planckdata/react-components"

export interface InfoTooltipProps {}

const useStyles = makeStyles<Theme, InfoTooltipProps>({
  info: {
    marginLeft: 6,
    marginTop: 2,
  },
})

export const InfoTooltip: React.FC<PropsWithChildren<InfoTooltipProps>> = ({ children, ...props }) => {
  const classes = useStyles(props)

  return <InformationPopover className={classes.info} text={children} />
}

export default InfoTooltip
