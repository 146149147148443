import React from "react"
import { ListIcon } from "@planckdata/react-components"
import { TextIconContainer } from "components/atoms"
import { TextIconProps, useStyles } from "./shared"
import { cn } from "@planckdata/components"

const BatchNameTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const classes = useStyles()
  return (
    <TextIconContainer text={value!} {...props}>
      <ListIcon className={cn(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export default React.memo(BatchNameTextIcon)
