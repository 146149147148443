import React from "react"
import { SingleBusinessPageContext, useSingleBusinessPage } from "./shared/SingleBusinessPage.hooks"
import { CaseDataProvider } from "./CaseDataContext"
import { createMap } from "@planckdata/typescript-utils"
import { SingleBusinessPageUIContext, useSingleBusinessPageUI } from "./shared/SingleBusinessPageUI.hook"

export const SBPOnboardingSteps = {
  tour: "tour",
  answerCodes: "answerCodes",
} as const

export type SBPOnboardingStep = typeof SBPOnboardingSteps[keyof typeof SBPOnboardingSteps]

export interface SBPOnboardingContextValue {
  step?: SBPOnboardingStep
  markStepComplete(step: SBPOnboardingStep): void
}

export const SBPOnboardingContext = React.createContext<SBPOnboardingContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markStepComplete: () => {},
})

export function useSBPOnboardingContext(): SBPOnboardingContextValue {
  const context = React.useContext(SBPOnboardingContext)

  return context
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SBPContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useSingleBusinessPage({
    sections: ["case", "geo", "reviews", "main_photos", "risk_factors"],
  })
  const ui = useSingleBusinessPageUI()

  const insightsMap = React.useMemo(() => {
    if (context.data?.case?.insights) {
      return createMap(context.data.case.insights, "name")
    }
  }, [context.data?.case?.insights])

  return (
    <>
      <SingleBusinessPageUIContext.Provider value={ui}>
        <SingleBusinessPageContext.Provider value={context}>
          <CaseDataProvider value={context.data} insightsMap={insightsMap} update={context.updateCaseData}>
            {children}
          </CaseDataProvider>
        </SingleBusinessPageContext.Provider>
      </SingleBusinessPageUIContext.Provider>
    </>
  )
}
