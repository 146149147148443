import React from "react"
import { TextIconProps, useStyles } from "./shared"
import { useTranslation } from "i18n"
import { TextIconContainer } from "components/atoms"
import { UserIcon } from "@planckdata/react-components"
import { cn } from "@planckdata/components"

const UploadedByTextIcon: React.FC<TextIconProps> = ({ value, iconProps, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer
      text={t("text_icons.case_info.uploaded_by", {
        username: value,
      })}
      {...props}
      role="presentation"
      aria-label="uploaded by"
    >
      <UserIcon className={cn(classes.iconSize, classes.opacity4)} {...iconProps} />
    </TextIconContainer>
  )
}

export default React.memo(UploadedByTextIcon)
