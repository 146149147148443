import { makeAutoObservable } from "mobx"
import { Routes } from "routes"

export const PopupState = {
  Closed: "closed",
  Form: "form",
  Success: "success",
} as const
export type PopupState = typeof PopupState[keyof typeof PopupState]

export type PopupOverrides = {
  topic?: string
  content?: string
}

class SupportButtonController {
  state: PopupState = PopupState.Closed
  topic = ""
  content = ""
  mounted = false

  constructor() {
    makeAutoObservable(this)
  }

  get isOpen(): boolean {
    return this.state !== PopupState.Closed
  }

  get isClosed(): boolean {
    return this.state === PopupState.Closed
  }

  get isExpanded(): boolean {
    return this.state !== PopupState.Closed
  }

  get isForm(): boolean {
    return this.state === PopupState.Form
  }

  get isSuccess(): boolean {
    return this.state === PopupState.Success
  }

  open(overrides: PopupOverrides = {}) {
    if (!this.mounted) {
      window.location.href = Routes.Support
      return
    }
    this.showForm(overrides)
  }
  close() {
    this.state = PopupState.Closed
  }
  reset({ topic, content }: PopupOverrides = {}) {
    this.topic = topic ?? ""
    this.content = content ?? ""
  }

  showSuccess() {
    this.state = PopupState.Success
  }
  showForm({ topic, content }: PopupOverrides = {}) {
    if (topic) {
      this.topic = topic
    }
    if (content) {
      this.content = content
    }
    this.state = PopupState.Form
  }
}

export const supportButtonController = new SupportButtonController()
