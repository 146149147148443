import { CrossIcon } from "@planckdata/react-components"
import { SvgIconProps } from "@planckdata/react-components/components/icons/Icon"
import React from "react"

export interface NoWebPresenceIconProps extends SvgIconProps {}

const NoWebPresenceIcon: React.FC<NoWebPresenceIconProps> = (props) => {
  return <CrossIcon {...props} color="error" />
}

export default NoWebPresenceIcon
