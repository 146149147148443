import { Routes } from "routes"
import { userStore } from "user-context"
import { getRedirectTo } from "utils"

export class SessionHelper {
  private tokenTimerId: number | undefined
  private isIdle = false

  onTokenExpired(): void {
    clearTimeout(this.tokenTimerId)
  }

  async onTokenUpdated(expiresIn?: number): Promise<void> {
    if (expiresIn != null) {
      this.tokenTimerId = window.setTimeout(() => this.tryRefreshToken(), expiresIn)
    }
  }

  onUserIdle(): void {
    this.isIdle = true
    clearTimeout(this.tokenTimerId)
  }

  async onUserActive(): Promise<void> {
    this.isIdle = false
    clearTimeout(this.tokenTimerId)
    await this.tryRefreshToken()
  }

  private async tryRefreshToken() {
    if (this.isIdle) {
      return
    }
    try {
      await userStore.refreshUserToken() // this will call onTokenChange with or without updated token
      const isAuthenticated = await userStore.isAuthenticated()
      if (isAuthenticated) {
        return
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("refresh failed", e)
    }

    // isAuthenticated is false or refresh failed, redirect to login page
    window.location.href = Routes.Login + `?returnTo=${getRedirectTo()}`
  }
}

export const sessionHelper = new SessionHelper()
