import React from "react"
import { hot } from "react-hot-loader/root"
import { Router } from "react-router-dom"
import { planckTheme, ThemeProvider, track, LoggerProvider } from "@planckdata/react-components"
import { observer } from "mobx-react-lite"
import { UserProvider, useUser } from "user-context"
import { I18nNSContext } from "@planckdata/i18n"
import { dispatchTrackEvent } from "analytics"
import AppRouter from "./AppRouter"
import AppLoader from "./AppLoader"
import { useTranslation, defaultLocale, i18nNamespace } from "i18n"
import { ENV } from "../../env"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ErrorPageProvider } from "../ErrorPages"
import API from "@planckdata/api"
import loggers, { history } from "logger"

const App: React.FC = track(undefined, { dispatch: dispatchTrackEvent })(
  observer(() => {
    const { i18n } = useTranslation()
    const user = useUser()

    API.defaults.headers = {
      ...API.defaults.headers,
      "App-Id": ENV.APP_NAME,
      "App-Version": ENV.APP_VERSION ?? "local",
    }

    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
      },
    })

    React.useEffect(() => {
      i18n.changeLanguage(user.current?.locale ?? defaultLocale)
    }, [i18n, user, user.current?.locale])

    return (
      <LoggerProvider loggers={loggers}>
        <I18nNSContext.Provider value={i18nNamespace}>
          <React.Suspense fallback={<AppLoader />}>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <Router history={history}>
                  <ThemeProvider theme={planckTheme}>
                    <ErrorPageProvider>
                      <AppRouter />
                    </ErrorPageProvider>
                  </ThemeProvider>
                </Router>
              </UserProvider>
            </QueryClientProvider>
          </React.Suspense>
        </I18nNSContext.Provider>
      </LoggerProvider>
    )
  }),
)

export default hot(App)
