import React from "react"
import { Button, CustomComponentProps } from "@planckdata/react-components"
import { ReactComponent as Image } from "./images/error-428.svg"
import { CustomErrorDialogProps, CustomErrorPage, CustomErrorPageProps } from "../../../pages/ErrorPages/CustomError"
import { useTranslation } from "../../../i18n"
import { useHistory } from "react-router"
import { Routes } from "routes"

export interface Error428PageProps extends CustomComponentProps<Partial<CustomErrorPageProps>> {
  //
}

export interface Error428DialogProps extends CustomComponentProps<Partial<Omit<CustomErrorDialogProps, "open">>> {
  open: boolean
}

/** @see {@link CustomErrorPage} for props you may override */
export const Error428Page: React.FC<Error428PageProps> = (props) => {
  const { className, ...rest } = props
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <CustomErrorPage
      className={className}
      titleKey="error_pages.428.title"
      descriptionKey="error_pages.428.description"
      image={<Image />}
      cta={({ ctx }) => (
        <Button
          onClick={() => {
            ctx.clearError()
            history.push(Routes.InsightsMapping)
          }}
        >
          {t("error_pages.428.cta")}
        </Button>
      )}
      {...rest}
    />
  )
}
