import React from "react"
import { Button, Column, Row, Text } from "@planckdata/components"
import HeadsetHelpIcon from "@planckdata/components/icons/HeadsetHelp"
import { useTranslation } from "i18n"

export interface SuccessStateProps {
  onClose: () => void
  onNewMessage: () => void
}

const SuccessState: React.FC<SuccessStateProps> = (props) => {
  const { onClose, onNewMessage } = props
  const { t } = useTranslation()

  return (
    <div className="py-14 px-8">
      <Column gapY={4} align="center">
        <div className="p-11 rounded-full border border-dark-10">
          {/** using a non-ds solid color because this icon has issues */}
          <HeadsetHelpIcon size="6xl" className="text-[#ACAEB2]" />
        </div>
        <Text color="dark-100" className="text-center">
          {t("support.popup.success_message")}
        </Text>
        <Row gap={1}>
          <Button color="ghost" onClick={onClose}>
            {t("support.popup.close")}
          </Button>
          <Button color="primary" onClick={onNewMessage}>
            {t("support.popup.new_message")}
          </Button>
        </Row>
      </Column>
    </div>
  )
}

export default React.memo(SuccessState)
