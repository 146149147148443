import React from "react"
import LocationDisabledIcon from "@planckdata/react-components/mui/icons/LocationDisabled"
import { SvgIconProps } from "@planckdata/react-components/components/icons/Icon"

export interface NoMatchIconProps extends SvgIconProps {}

const NoMatchIcon: React.FC<NoMatchIconProps> = (props) => {
  return <LocationDisabledIcon {...props} color="error" />
}

export default NoMatchIcon
