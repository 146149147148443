import { useInputHighlight } from "components/organisms/OpenCaseForm/OpenCaseForm.hooks"
import React from "react"
import { ImagesTabStore } from "../layout/components/BasicInfo/ImagesTabStore"

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export interface SingleBusinessPageUIContext {
  inputHighlight: ReturnType<typeof useInputHighlight>[0]
  setInputHighlight: ReturnType<typeof useInputHighlight>[1]
  advancedSearchOpen: boolean
  setAdvancedSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
  imagesTabs: ImagesTabStore
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
}

export const SingleBusinessPageUIContext = React.createContext<SingleBusinessPageUIContext>({
  inputHighlight: false,
  setInputHighlight: noop,
  advancedSearchOpen: false,
  setAdvancedSearchOpen: noop,
  imagesTabs: new ImagesTabStore(),
  pageIndex: 0,
  setPageIndex: noop,
})

export function useSingleBusinessPageUI(): SingleBusinessPageUIContext {
  const [inputHighlight, setInputHighlight] = useInputHighlight()
  const [advancedSearchOpen, setAdvancedSearchOpen] = React.useState(false)
  const [imagesTabStore] = React.useState(new ImagesTabStore())
  const [pageIndex, setPageIndex] = React.useState<number>(0)

  return {
    inputHighlight,
    setInputHighlight,
    advancedSearchOpen,
    setAdvancedSearchOpen,
    imagesTabs: imagesTabStore,
    pageIndex,
    setPageIndex,
  }
}

/**
 * Hook for accessing the data about SBP **UI** from the context (must be provided elsewhere)
 *
 * @see {@link useSingleBusinessPageUI} for creating the state
 */
export const useSingleBusinessPageUIContext = (): SingleBusinessPageUIContext => {
  return React.useContext(SingleBusinessPageUIContext)
}
