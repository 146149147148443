import React from "react"
import { colors, alpha, Grid, PageContentBox, Typography, makeStyles } from "@planckdata/react-components"
import { Trans, useTranslation } from "i18n"
import { ExclamationIcon } from "@planckdata/react-components/components/icons"
import LinkUploadFile from "../../atoms/LinkUploadFile"
import { BatchWarningBoxProps } from "./BatchStatusProps"

const useStyles = makeStyles((theme) => ({
  pageContentBox: {
    padding: 10,
    backgroundColor: `${alpha(colors.warning, 0.04)}`,
    border: `2px solid ${alpha(colors.warning, 0.24)}`,
    width: "auto",
    minWidth: 500,
  },
  exclamationIcon: {
    verticalAlign: "middle",
    fontSize: 20,
  },
  exclamationContainer: {
    borderRadius: "50%",
    color: "#a18501",
    background: `${alpha(colors.warning, 0.2)}`,
  },
  fileNameStatus: {
    color: colors.success,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    lineClamp: 2,
    "-webkit-box-orient": "vertical",
  },
  errorList: {
    marginTop: theme.spacing(0.5),
  },
}))

export const BatchWarningBox: React.FC<BatchWarningBoxProps> = ({
  fileName,
  totalCount,
  invalidCount,
  errorSummaryPerRow = [],
  errorSummaryCombined = [],
  downloadLink,
  onFileSelected,
  acceptFileTypes,
  contentBoxProps,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageContentBox {...contentBoxProps} className={classes.pageContentBox}>
      <Grid data-testid="BatchUploadWarning" container spacing={1}>
        <Grid item style={{ margin: "10px 5px 10px 0px", flexBasis: "unset" }} xs={1}>
          <div className={classes.exclamationContainer}>
            <ExclamationIcon className={classes.exclamationIcon} />
          </div>
        </Grid>
        <Grid item xs={11}>
          <Typography size={12} weight={600} component="span">
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography size={12} weight={600} component="span">
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Trans
                        i18nKey="batch_status.warning_text"
                        values={{ fileName, totalCases: totalCount, invalidCases: invalidCount }}
                        components={{ b: <span className={classes.fileNameStatus} /> }}
                      />
                    </Grid>
                    {errorSummaryPerRow.length > 0 || errorSummaryCombined.length > 0 ? (
                      <ul className={classes.errorList}>
                        {errorSummaryPerRow.length > 0 && (
                          <Grid item>
                            {errorSummaryPerRow.map((invalidCase, i) => (
                              <li key={i}>
                                {t(
                                  invalidCase.business_name
                                    ? "batch_status.warning_per_row_with_name"
                                    : "batch_status.warning_per_row",
                                  {
                                    row: invalidCase.row,
                                    name: invalidCase.business_name,
                                    message: invalidCase.reason,
                                  },
                                )}
                              </li>
                            ))}
                          </Grid>
                        )}
                        {errorSummaryCombined.length > 0 && (
                          <Grid item>
                            {errorSummaryCombined.map((invalidCase, i) => (
                              <li key={i}>
                                {t("batch_status.warning_combined", {
                                  count: invalidCase.number_of_cases,
                                  message: invalidCase.reason,
                                })}
                              </li>
                            ))}
                          </Grid>
                        )}
                      </ul>
                    ) : null}
                    <Grid item>
                      <a href={downloadLink} target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                        <Typography size={12} weight={600} component="span">
                          {t("batch_status.see_errors")}
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid item>
                  <LinkUploadFile
                    text={t("batch_status.replace")}
                    onFileSelected={onFileSelected}
                    acceptFileTypes={acceptFileTypes}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </PageContentBox>
  )
}

export default BatchWarningBox
