import React from "react"
import SupportButton from "components/atoms/SupportButton"

export interface ErrorPageSupportButtonProps {
  errorMessage: string
}

/**
 * A wrapper around SupportButton for error pages.
 *
 * @example
 * ```tsx
 * <ErrorPageSupportButton />
 * ```
 */
export const ErrorPageSupportButton: React.FC<ErrorPageSupportButtonProps> = (props) => {
  const { errorMessage } = props
  return <SupportButton source="ErrorPage" additionalData={{ errorMessage }} />
}

export default ErrorPageSupportButton
