import { useInputValidator } from "@planckdata/react-components/hooks/input_validator"
import { TFunction } from "i18next"
import { validatePhoneFormat, validateURL } from "utils/validators"

export function useWebsiteValidator(t: TFunction, website: string): readonly [boolean, string | undefined] {
  return useInputValidator(website, [
    {
      message: t("advanced_search_form.website.error"),
      isError: (value) => Boolean(value.length && !validateURL(value, true)),
    },
  ])
}
export function useCustomerPolicyIdValidator(customer_policy_id: string): readonly [boolean, string | undefined] {
  return useInputValidator(customer_policy_id, [])
}

export function useDbaValidator(t: TFunction, dba: string): readonly [boolean, string | undefined] {
  const minLen = 3

  return useInputValidator(dba, [
    {
      message: t("advanced_search_form.dba.error", { count: minLen }),
      isError: (value) => Boolean(value.length && value.length < minLen),
    },
  ])
}

export function useAddressValidator(t: TFunction, address: string): readonly [boolean, string | undefined] {
  const minLen = 2

  return useInputValidator(address, [
    {
      message: t("advanced_search_form.dba.error", { count: minLen }),
      isError: (value) => Boolean(value.length && value.length < minLen),
    },
  ])
}

export function usePhoneValidator(t: TFunction, phone: string): readonly [boolean, string | undefined] {
  const minLen = 9
  const maxLen = 15
  const cleanReplaceRegex = /[^0-9]/g

  return useInputValidator(phone, [
    {
      message: t("advanced_search_form.phone.errors.format"),
      isError: (value) => Boolean(value.length && !validatePhoneFormat(value)),
    },
    {
      message: t("advanced_search_form.phone.errors.min_length", { value: minLen }),
      isError: (value) => Boolean(value.length && value.replace(cleanReplaceRegex, "").length < minLen),
    },
    {
      message: t("advanced_search_form.phone.errors.max_length", { value: maxLen - 1 }),
      isError: (value) => Boolean(value.length && value.replace(cleanReplaceRegex, "").length > maxLen - 1),
    },
  ])
}
