import React from "react"
import cls from "clsx"
import { alpha, Button, ButtonProps, colors, makeStyles, Typography } from "@planckdata/react-components"
import { ArrowDownIcon } from "@planckdata/react-components/components/icons"

export interface FilterButtonProps extends ButtonProps {
  filterSelected: boolean
  icon?: React.ReactElement<any>
}

const useStyles = makeStyles({
  arrowIcon: {
    transition: "all .3s linear",
  },
  openedIcon: {
    transform: "rotate(180deg)",
  },
  button: {
    border: `1px solid ${alpha("#9A9499", 0.2)}`,
    maxWidth: "100%",
    justifyContent: "end",
    "& .MuiButton-label": {
      overflow: "hidden",
      color: colors.foregroundAltText,
      whiteSpace: "nowrap",
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },
    "& .MuiButton-endIcon": {
      flex: 1,
      justifyContent: "flex-end",
    },
  },
  icon: {
    color: colors.foregroundAltText,
  },
  selected: {
    color: colors.primary,
    fontWeight: 600,
    "& .MuiButton-label": {
      color: colors.primary,
    },
  },
})

export const FilterButton: React.FC<FilterButtonProps> = ({ children, filterSelected, icon, className, ...props }) => {
  const classes = useStyles(props)
  const startIcon = icon
    ? React.cloneElement(icon, {
        className: cls({ [classes.selected]: filterSelected, [classes.icon]: !filterSelected }),
      })
    : null
  const dropdownCaretIcon = <ArrowDownIcon className={cls(classes.arrowIcon, { [classes.openedIcon]: !open })} />

  return (
    <Button
      variant="outlined"
      startIcon={startIcon}
      endIcon={dropdownCaretIcon}
      className={cls(className, classes.button, { [classes.selected]: filterSelected })}
      {...props}
    >
      <Typography size={16} noWrap color="textSecondary" className={filterSelected ? classes.selected : ""}>
        {children}
      </Typography>
    </Button>
  )
}
