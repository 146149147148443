export function validateURL(url: string, guessScheme = false): boolean {
  let uri: URL
  try {
    uri = new URL(url)
  } catch (error) {
    if (!guessScheme) {
      return false
    }
    try {
      uri = new URL("https://" + url)
    } catch (error) {
      return false
    }
  }
  return uri && uri.host.includes(".")
}

export function validatePhoneFormat(phone: string): boolean {
  const validRegex = /^(?:(?:\+|00)?(?:[1-9]\d{0,2}))?(?:[-. (]*(\d{2,3}))?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?$/
  return validRegex.test(phone)
}
