import React from "react"
import { colors, ElementComponentProps, makeStyles, Typography } from "@planckdata/react-components"
import { RefreshIcon } from "@planckdata/react-components/components/icons"

const useStyles = makeStyles(() => ({
  refreshContainer: {
    color: colors.foregroundAltText,
  },
}))

export interface LinkUploadFileProps extends ElementComponentProps<HTMLSpanElement> {
  text: string
  onFileSelected: any
  acceptFileTypes?: string
}

export const LinkUploadFile: React.FC<LinkUploadFileProps> = ({ text, onFileSelected, acceptFileTypes, ...props }) => {
  const classes = useStyles(props)
  const fileRef: any = React.createRef()

  function triggerClick() {
    fileRef.current.click()
  }

  return (
    <span {...(props as any)}>
      <span className={classes.refreshContainer}>
        <RefreshIcon />
      </span>
      <Typography
        size={12}
        weight={600}
        component="span"
        color="textSecondary"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={triggerClick}
      >
        <input
          type="file"
          style={{ display: "none" }}
          onChange={onFileSelected}
          ref={fileRef}
          accept={acceptFileTypes ?? "*"}
        />
        {text}
      </Typography>
    </span>
  )
}

export default LinkUploadFile
