import React from "react"
import { ExportIcon, FolderIcon, EnvelopeClosedIcon, DocumentIcon } from "@planckdata/react-components/components/icons"
import { SideMenuItemProps } from "./SideMenuItem"
import { Routes } from "routes"
import { excludeIncludeValues } from "@planckdata/react-components"
import { ENV } from "../../../env"

const intlPrefix = (s: string) => `side_menu.${s}`

export const MenuItems: Omit<SideMenuItemProps, "sideMenuOpen">[] = [
  {
    icon: <ExportIcon />,
    text: intlPrefix("new_submission"),
    route: Routes.GenerateInsights,
    allowMatch: (path) => path.startsWith(Routes.GenerateInsights) || path === "/",
    // items: [
    //   {
    //     text: intlPrefix("single_business"),
    //     route: Routes.SingleBusiness,
    //     allowMatch: (path) => path === Routes.Home || path === GenerateInsightsBasePath,
    //   },
    //   {
    //     text: intlPrefix("list_of_businesses"),
    //     route: Routes.Batch,
    //     allowMatch: (path) => path.startsWith("/generate-insights/batch"),
    //   },
    // ],
  },
  {
    icon: <FolderIcon />,
    text: intlPrefix("my_businesses"),
    route: Routes.MyBusinesses,
    // items: [
    //   {
    //     text: prefix("completed"),
    //     route: Routes.MyBusinesses,
    //   },
    //   {
    //     text: prefix("waiting_for_feedback"),
    //     route: Routes.MyBusinessesWaiting,
    //   },
    // ],
  },
  {
    icon: <DocumentIcon />,
    text: intlPrefix("insights_mapping"),
    route: Routes.InsightsMapping,
    visible: (user) =>
      !ENV.IS_PLUS_PLATFORM &&
      excludeIncludeValues({
        values: user?.permissions,
        includeFor: ["ShowInsightsMapping"],
      }),
  },
  {
    icon: <EnvelopeClosedIcon />,
    text: intlPrefix("support"),
    route: Routes.Support,
  },
]
