import React from "react"
import { CustomComponentProps } from "@planckdata/react-components"
import { CustomErrorDialogProps, CustomErrorPage, CustomErrorPageProps } from "../../../pages/ErrorPages/CustomError"

export interface ErrorOfflinePageProps extends CustomComponentProps<Partial<CustomErrorPageProps>> {
  //
}

export interface ErrorOfflineDialogProps extends CustomComponentProps<Partial<Omit<CustomErrorDialogProps, "open">>> {
  open: boolean
}

/** @see {@link CustomErrorPage} for props you may override */
export const ErrorOfflinePage: React.FC<ErrorOfflinePageProps> = (props) => {
  const { className, ...rest } = props

  return (
    <CustomErrorPage
      className={className}
      titleKey="error_pages.offline.title"
      descriptionKey="error_pages.offline.description"
      image={<ErrorImage />}
      contentWidth={432}
      {...rest}
    />
  )
}

// this is a static component, because it will probably fail to load, if the user turned offline mid-request :)
const ErrorImage = () => {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="98" cy="99.5007" r="80.0007" fill="#F2F4FB" />
      <g filter="url(#filter0_d_601_47880)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.1083 106.127C94.3261 106.127 90.5809 106.872 87.0866 108.319C83.5924 109.767 80.4175 111.889 77.7433 114.563C75.4003 116.907 71.6013 116.907 69.258 114.564C66.9146 112.221 66.9143 108.422 69.2573 106.079C73.0458 102.29 77.5437 99.2839 82.494 97.2331C87.4443 95.1824 92.75 94.1269 98.1083 94.1269C103.467 94.1269 108.772 95.1824 113.723 97.2331C118.673 99.2839 123.171 102.29 126.959 106.079C129.302 108.422 129.302 112.221 126.959 114.564C124.615 116.907 120.816 116.907 118.473 114.563C115.799 111.889 112.624 109.767 109.13 108.319C105.636 106.872 101.891 106.127 98.1083 106.127ZM85.6637 122.485C88.9642 119.184 93.4407 117.33 98.1083 117.33C102.776 117.33 107.252 119.184 110.553 122.485C113.853 125.785 115.708 130.261 115.708 134.929C115.708 139.597 113.853 144.073 110.553 147.374C107.252 150.674 102.776 152.528 98.1083 152.528C93.4407 152.528 88.9642 150.674 85.6637 147.374C82.3632 144.073 80.509 139.597 80.509 134.929C80.509 130.261 82.3632 125.785 85.6637 122.485ZM98.1083 129.33C96.6233 129.33 95.1991 129.92 94.149 130.97C93.0989 132.02 92.509 133.444 92.509 134.929C92.509 136.414 93.0989 137.838 94.149 138.888C95.1991 139.939 96.6233 140.528 98.1083 140.528C99.5933 140.528 101.018 139.939 102.068 138.888C103.118 137.838 103.708 136.414 103.708 134.929C103.708 133.444 103.118 132.02 102.068 130.97C101.018 129.92 99.5933 129.33 98.1083 129.33Z"
          fill="url(#paint0_linear_601_47880)"
        />
      </g>
      <g opacity="0.6" filter="url(#filter1_d_601_47880)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.276 81.7546C121.913 52.3856 74.3039 52.3856 44.9403 81.7546C42.5974 84.0979 38.7984 84.0983 36.455 81.7554C34.1117 79.4124 34.1113 75.6134 36.4543 73.2701C70.5044 39.2138 125.712 39.2138 159.762 73.2701C162.105 75.6134 162.105 79.4124 159.762 81.7554C157.418 84.0983 153.619 84.0979 151.276 81.7546ZM134.875 98.1623C114.571 77.8577 81.646 77.8577 61.3414 98.1623C58.9983 100.505 55.1993 100.505 52.8562 98.1623C50.513 95.8191 50.513 92.0201 52.8562 89.677C77.847 64.6861 118.37 64.6861 143.36 89.677C145.704 92.0201 145.704 95.8191 143.36 98.1623C141.017 100.505 137.218 100.505 134.875 98.1623Z"
          fill="url(#paint1_linear_601_47880)"
        />
      </g>
      <rect x="8.5" y="134.797" width="31.3233" height="14.5151" stroke="#DBE2E2" />
      <rect x="159.409" y="70.3724" width="33.1635" height="6.73608" fill="#E5E9F2" />
      <rect x="164.495" y="116.83" width="9.62297" height="9.62297" fill="#7F70FA" />
      <rect x="15.7866" y="40.7496" width="9.62297" height="9.62297" fill="#00E1C9" />
      <defs>
        <filter
          id="filter0_d_601_47880"
          x="58.5003"
          y="88.1269"
          width="79.2161"
          height="76.4016"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_601_47880" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_601_47880" result="shape" />
        </filter>
        <filter
          id="filter1_d_601_47880"
          x="25.6973"
          y="41.7278"
          width="144.822"
          height="70.1918"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_601_47880" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_601_47880" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_601_47880"
          x1="98.1083"
          y1="129.667"
          x2="98.1085"
          y2="160.053"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0956002" stopColor="white" />
          <stop offset="0.531331" stopColor="#EBF1F1" />
          <stop offset="0.694501" stopColor="#D4DDE4" />
          <stop offset="0.808055" stopColor="#BFCAD8" />
          <stop offset="0.873422" stopColor="#A2B1C7" />
          <stop offset="0.932345" stopColor="#AACCEB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_601_47880"
          x1="98.1083"
          y1="-48.5453"
          x2="98.0988"
          y2="335.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0956002" stopColor="white" />
          <stop offset="0.531331" stopColor="#EBF1F1" />
          <stop offset="0.694501" stopColor="#D4DDE4" />
          <stop offset="0.808055" stopColor="#BFCAD8" />
          <stop offset="0.873422" stopColor="#A2B1C7" />
          <stop offset="0.932345" stopColor="#AACCEB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
