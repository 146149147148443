export const StorageKeys = {
  /**
   * Last used businesses sort option
   *
   * Possible values: @see CaseSortBy
   */
  MY_BUSINESSES_SORT_BY: "my-businesses.sort-by",

  /**
   * Last used businesses search filters
   *
   * Possible values: default | modified
   */
  MY_BUSINESSES_FILTERS_STATE: "my-businesses.filters-state",

  /**
   * Override user's `newSingleBusinessPage` flag
   *
   * Possible values: true | false
   */
  SBP_V2_FLAG_OVERRIDE: "newSingleBusinessPage",

  /**
   * Mark if confetti was triggered for user when switched to SBP v2
   *
   * Possible values: true | false
   */
  SBP_V2_SHOW_CONFETTI: "show",

  /**
   * Mark if SBP v2 walkthrough tour was dismissed
   *
   * Possible values: true | false
   */
  SBP_V2_WALKTHROUGH: "dismissed",

  /**
   * Last used insights sort option
   *
   * Possible values: @see SortInsightsMode
   */
  INSIGHTS_SORT_MODE: "insights.sort-by",

  /**
   * Mark if SBP v2 rollout popup was dismissed
   *
   * Possible values: true | false
   */
  SBP_ROLLOUT_POPUP: "newSingleBusinessPage",

  /**
   * Number of cases viewed on SBP v1.0. Used to show toggle button tooltip
   *
   * Possible values: number
   */
  SBP_TOGGLE_CASE_COUNT: "single-business.case-count",

  /**
   * Don't show confidence walkthrough popup
   *
   * Possible values: true | false
   */
  SBP_V2_DISABLE_CONFIDENCE_WALKTHROUGH: "single-business.disable-confidence-walkthrough",

  /**
   * Last version of the SBP popup that was dismissed.
   *
   * Used to determine when to show new popup with new content.
   *
   * Used with namespace `StorageNS.POPUPS`
   */
  SBP_CHANGELOG_DISMISSED_VERSION: "changelog.sbp.version",

  /** Skip internal checks on main route (DEV ONLY!) */
  DEV_SKIP_INTERNAL_CHECK: "skipInternalCheck",

  /**
   * Whether MS Clarity was consented (enabled) or not (disabled)
   * This is saved in localStorage because it's used before the user is logged in,
   * after the initial consent is given.
   */
  CLARITY_CONSENT: "clarityConsent",

  /**
   * If set to `true`, set MixPanel debug mode
   */
  MIXPANEL_DEBUG: "__mp_debug__",

  /**
   * If set to `true`, overrides url check and force IS_PLUS_PLATFORM to be true
   */
  FORCE_PLUS: "__force_plus__",
}

export const StorageNS = {
  /** Pertaining to user-wide flags */
  FLAGS: "flags",

  /** PWA features */
  PWA: "pwa",

  /**
   * Confetti for SBP
   * TODO: move to SBP/PWA namespace
   */
  CONFETTI: "confetti",

  /**
   * Insights mapping tour
   * TODO: move to INSIGHTS_MAPPING namespace
   */
  INSIGHTS_MAPPING_TOUR: "insightsMappingTour",

  /** Pop-up states */
  POPUPS: "popups",

  /** DEV MODE ONLY */
  DEV: "dev",
}

export default StorageKeys
