export const Routes = {
  Home: "/",
  Login: "/login",
  ForgotPassword: "/password/forgot",
  UpdatePassword: "/password/update",
  ActivateAccount: "/password/create",
  NewSubmission: "/new",
  ChangePassword: "/change-password",
  Register: "/sign-up",
  Support: "/support",
  AskMax: "/ask-max",
  TermsAndConditions: "/terms",

  //
  MyBusinesses: "/businesses",
  SingleBusinessPage: "/businesses/:caseId",
  // Dashboard: "/dashboard",
  InsightsMapping: "/insights-mapping",

  // generate insights
  SingleBusiness: "/generate-insights/single",
  GenerateInsights: "/generate-insights",

  // Batch
  Batch: "/generate-insights/batch",
  Upload: "/generate-insights/batch/upload",
  Processing: "/generate-insights/batch/processing",
  DownloadExportedFile: "/export",
} as const

export type Routes = typeof Routes[keyof typeof Routes]

export const batchRoutes = {
  businesses: Routes.MyBusinesses,
  upload: Routes.Upload,
  processing: Routes.Processing,
} as const

export type BatchRoutes = typeof batchRoutes[keyof typeof batchRoutes]

export const OpenBusinessRoute = {
  single_business: Routes.MyBusinesses,
} as const

export type OpenBusinessRoute = typeof OpenBusinessRoute[keyof typeof OpenBusinessRoute]

export function getSingleBusinessPageRoute(caseId: string): string {
  return `${Routes.MyBusinesses}/${caseId}`
}

export const MyBusinessesRoutes = {
  single_business: Routes.MyBusinesses,
  download_exported_file: Routes.DownloadExportedFile,
} as const

export type MyBusinessesRoutes = typeof MyBusinessesRoutes[keyof typeof MyBusinessesRoutes]

export const InsightMappingTabs = {
  MyPackages: "my_packages",
  MorePackages: "more_packages",
  FuturePackages: "future_packages",
} as const

export type InsightMappingTabs = typeof InsightMappingTabs[keyof typeof InsightMappingTabs]

export const INSIGHTS_MAPPING_TAB_ROUTES: Record<InsightMappingTabs, string> = {
  [InsightMappingTabs.MyPackages]: "",
  [InsightMappingTabs.MorePackages]: "/more-packages",
  [InsightMappingTabs.FuturePackages]: "/future-packages",
}

export function getInsightMappingRoute(tab: InsightMappingTabs): string {
  return Routes.InsightsMapping + INSIGHTS_MAPPING_TAB_ROUTES[tab]
}
