import React from "react"
import { colors, Grid, Typography, makeStyles, alpha } from "@planckdata/react-components"
import { MessagesIcon } from "@planckdata/react-components/components/icons"
import { Trans } from "i18n"

const useStyles = makeStyles({
  indicatorBox: {
    backgroundColor: alpha(colors.linkVisited, 0.2),
    borderRadius: 34,
  },
  marginLeft: {
    marginLeft: 5,
  },
  marginRight: {
    marginRight: 5,
  },
})

export interface NewEvidenceIndicatorProps {
  evidenceNum?: number
}

export const NewEvidenceIndicator: React.FC<NewEvidenceIndicatorProps> = ({ evidenceNum }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.indicatorBox}>
      <Grid item>
        <MessagesIcon className={evidenceNum ? classes.marginLeft : ""} />
      </Grid>
      <Grid item className={classes.marginRight} hidden={!evidenceNum}>
        <Typography size={14} weight={600} display="inline">
          <Trans i18nKey="general.new_evidence_indicator.new_replies" values={{ evidenceCount: evidenceNum }} />
        </Typography>
        <Typography size={14} display="inline" className={classes.marginLeft}>
          <Trans i18nKey="general.new_evidence_indicator.from_planck">from Planck</Trans>
        </Typography>
      </Grid>
    </Grid>
  )
}
