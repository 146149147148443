import { UserAPI } from "@planckdata/api"
import { ParsedUser } from "@planckdata/storage"
import { useLocalStorage } from "@planckdata/typescript-utils"
import { ENV } from "env"
import useTrackEvents from "hooks/track-events.hook"
import React from "react"
import StorageKeys, { StorageNS } from "storageKeys"
import { PWAUser, useUser } from "user-context"

export interface ClarityConsent {
  enabled: boolean | undefined
  dialogVisible: boolean
  updateConsent: (consent: boolean) => Promise<void>
}

const CLARITY_CONSENT_CHANGED_EVENT = "ClarityConsentChanged"
const CLARITY_LOADED_EVENT = "ClarityLoaded"

export function useClarityConsent(): Readonly<ClarityConsent> {
  const { current: user } = useUser()
  const userSetting = user?.userSettings?.ClarityConsented
  const cache = useLocalStorage<boolean>(StorageNS.POPUPS, StorageKeys.CLARITY_CONSENT)
  const cachedValue = cache.get()
  const { clarity: events } = useTrackEvents()
  const dialogVisible = Boolean(user && userSetting == null && cachedValue == null)
  const [clarityLoaded, setClarityLoaded] = React.useState(Boolean(window.clarity))

  function emitChanged(enabled: boolean) {
    const event = new CustomEvent(CLARITY_CONSENT_CHANGED_EVENT, { detail: { consent: enabled } })
    window.dispatchEvent(event)
  }

  async function updateConsent(enabled: boolean) {
    cache.save(enabled)
    emitChanged(enabled)
    await UserAPI.updateSettings({ ClarityConsented: enabled })
    if (enabled) {
      events.consentGiven()
    } else {
      events.consentRejected()
    }
  }

  const addStaticTags = React.useCallback(() => {
    window.clarity("set", "appName", ENV.APP_NAME)
    window.clarity("set", "environment", ENV.ENV_NAME)
  }, [])

  const addUserTags = React.useCallback((user: ParsedUser<PWAUser>) => {
    window.clarity("set", "username", user.username)
    window.clarity("set", "orgName", user.orgName)
    window.clarity("set", "orgType", user.orgType)
    window.clarity("set", "internal", user.internal)
  }, [])

  React.useEffect(() => {
    if (userSetting != null && cachedValue == null) {
      cache.save(userSetting)
      emitChanged(userSetting)
    }
  }, [cache, cachedValue, userSetting])

  React.useEffect(() => {
    function onClarityLoaded() {
      setClarityLoaded(true)
    }
    window.addEventListener(CLARITY_LOADED_EVENT, onClarityLoaded)
    return () => {
      window.removeEventListener(CLARITY_LOADED_EVENT, onClarityLoaded)
    }
  }, [])

  React.useEffect(() => {
    if (clarityLoaded) {
      addStaticTags()
      if (user) {
        addUserTags(user)
      }
    }
  }, [user, clarityLoaded, addStaticTags, addUserTags])

  return {
    enabled: cachedValue ?? userSetting,
    dialogVisible,
    updateConsent,
  } as const
}
