import React from "react"
import { makeStyles, Typography, Grid } from "@planckdata/react-components"

const useStyles = makeStyles(() => ({
  titleContainer: {
    marginBottom: 4,
    marginLeft: 16,
  },
}))

export interface FilterInputWrapperProps {
  title: string
  children: JSX.Element
}

export const FilterInputWrapper: React.FC<FilterInputWrapperProps> = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Grid container direction={"column"} data-testid="FilterInput">
      <div className={classes.titleContainer}>
        <Typography size={16} weight={600} lineHeight={32}>
          {title}
        </Typography>
      </div>
      {children}
    </Grid>
  )
}

export default FilterInputWrapper
