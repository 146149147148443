import { createBrowserHistory } from "history"
import { getHostingEnv } from "@planckdata/typescript-utils"
import { ENV } from "env"
import { ConsoleLogger, LogLevel, Logger, SentryLogger, SentryOptions } from "@planckdata/react-components"

export const history = createBrowserHistory()
const loggers: Array<Logger> = []

const environment = ENV.IS_DEBUG ? "local" : getHostingEnv(undefined, true)
if (ENV.IS_DEBUG) {
  loggers.push(new ConsoleLogger())
}

const sentryOptions: SentryOptions = {
  dsn: ENV.SENTRY_DSN,
  environment,
  logLevel: ENV.LOG_LEVEL as LogLevel,
  release: `${ENV.APP_NAME}:${ENV.APP_VERSION}`,
  initOptions: {
    debug: false,
    initialScope: {
      tags: {
        appName: ENV.APP_NAME,
        appVersion: ENV.APP_VERSION,
      },
    },
  },
}

loggers.push(new SentryLogger(sentryOptions))

export default loggers
