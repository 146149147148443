import React from "react"
import SideMenuItemComponent, { SideMenuItemProps } from "./SideMenuItem"

export type SideMenuItem = Omit<SideMenuItemProps, "sideMenuOpen">

export interface SideMenuItemsProps {
  items: Array<SideMenuItem>
  open: boolean
}

const SideMenuItems: React.FC<SideMenuItemsProps> = ({ items, open }) => {
  return (
    <>
      {items.map((item, index) => (
        <SideMenuItemComponent key={index} sideMenuOpen={open} {...item} />
      ))}
    </>
  )
}

export default React.memo(SideMenuItems)
