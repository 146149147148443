import React from "react"
/* eslint-disable @typescript-eslint/ban-types */
import { ErrorReporter, useLogger } from "@planckdata/react-components"
import { AxiosError } from "axios"
import { Error404Page } from "../../components/molecules/ErrorComponents"
import { ErrorGenericPage } from "../../components/molecules/ErrorComponents/ErrorGeneric"
import { useLocation } from "react-router-dom"
import { Location } from "history"
import { ErrorOfflinePage } from "../../components/molecules/ErrorComponents/ErrorOffline"
import { Error429Page } from "../../components/molecules/ErrorComponents/Error429"
import { Error428Page } from "../../components/molecules/ErrorComponents/Error428"
import useTrackEvents from "hooks/track-events.hook"

export type ErrorPageType = "page" | "dialog"

export interface ErrorPageContext {
  /** The current error code, if any */
  errorCode: ErrorCode | null
  /** The current error, if any */
  error: Error | null
  /** The current error component, if any */
  errorComponent: ErrorComponentType | null
  /** The type of page to render. If `page`, the error page will be rendered as a full page. If `dialog`, the error
   * page will be rendered as a dialog. Defaults to `page`. */
  type: ErrorPageType | null
  /**
   * Manually set an error
   * @param options.code The error code to set
   * @param options.error The error to set
   * @param options.component The component to render (if not set, will use the default error page for the given code,
   * or a 500 error page if no default is set)
   */
  setError(options: SetErrorOptions): void
  clearError(): void
  /**
   * Infer the error code from an error, if possible
   * @param error The error to infer from
   * @param options.strict When `true`, any error that is not an inferrable error, will be transformed into a 500 error.
   * When `false`, any error that is not an inferrable error, will be ignored (thus allowing for retries).
   * Defaults to `true`.
   * @param options.components A map of error codes to components to render for those codes. If the error code is not
   * in this map, the default error page will be used for that code, if possible. You can supply a `default` key to set
   * the default error page for all codes that do not have a specific error page.
   * @param options.type The type of page to render. If `page`, the error page will be rendered as a full page. If `dialog`,
   * the error page will be rendered as a dialog. Defaults to `page`.
   */
  inferError(error: unknown, options?: InferErrorOptions): void
}

export interface SetErrorOptions {
  /** The error code to set */
  code?: ErrorCode
  /** The error to set */
  error?: Error
  /** The component to render (if not set, will use the default error page for the given code,
   * or a 500 error page if no default is set) */
  component?: ErrorComponentType
  /** The type of page to render. If `page`, the error page will be rendered as a full page. If `dialog`, the error
   * page will be rendered as a dialog. Defaults to `page`. */
  type?: ErrorPageType
}

export interface InferErrorOptions {
  /**
   * When `true`, any error that is not an inferrable error, will be transformed into a 500 error.
   * When `false`, any error that is not an inferrable error, will be ignored (thus allowing for retries).
   * Defaults to `true`.
   */
  strict?: boolean
  /**
   * A map of error codes to components to render for those codes. If the error code is not
   * in this map, the default error page will be used for that code, if possible. You can supply a `default` key
   * to set the default error page for all codes that do not have a specific error page.
   */
  components?: Partial<Record<ErrorCode | "unknown", React.ReactNode>>
  /**
   * The type of page to render. If `page`, the error page will be rendered as a full page. If `dialog`, the error
   * page will be rendered as a dialog. Defaults to `page`.
   */
  type?: ErrorPageType
}

export const ErrorPageContext = React.createContext<ErrorPageContext>({
  error: null,
  errorCode: null,
  errorComponent: null,
  type: "page",
  setError: () => null,
  clearError: () => null,
  inferError: () => null,
})
type ErrorComponentType = React.ReactNode
type CustomErrorCode = "offline" | "unknown"
type ErrorCode = number | CustomErrorCode

/** Map of error codes and their error pages. When no code is matched, `default` is used */
const defaultErrorPages: Record<ErrorCode, ErrorComponentType> = {
  unknown: <ErrorGenericPage />,
  offline: <ErrorOfflinePage />,
  404: <Error404Page />,
  428: <Error428Page />,
  429: <Error429Page />,
}

/** Map of error codes and their error page types. When no code is matched, `default` is used */
const defaultErrorTypes: Record<ErrorCode, ErrorPageType> = {
  unknown: "page",
  offline: "page",
}

function defaultErrorPage(code: ErrorCode): ErrorComponentType {
  code ??= 500
  return defaultErrorPages[code] ?? defaultErrorPages.unknown
}
function defaultErrorType(code: ErrorCode): ErrorPageType {
  code ??= 500
  return defaultErrorTypes[code] ?? defaultErrorTypes.unknown
}

/**
 * Container for error boundary
 *
 * Use `props.children` to render normal content.
 *
 * If a component throws an error, it will be caught by the error boundary and the error will be displayed.
 * You can manually catch errors by calling `setError` or `inferError` on the context.
 *
 * There are default error components for various error codes, but you can override them by passing a `components`
 * object to `inferError`, or by passing a `component` to `setError`.
 *
 * @see {@link useErrorPage} for the hook to use in child components
 * @see {@link ErrorPageContext.setError} for manually setting an error
 * @see {@link ErrorPageContext.inferError} for inferring an error from an error or object
 */
export const ErrorPageProvider: React.FC<React.PropsWithChildren<Partial<Omit<InferErrorOptions, "type">>>> = ({
  children,
  components: _components,
  strict: _strict,
}) => {
  const { trackErrorPageView } = useTrackEvents()
  const [error, setError] = React.useState<Error | null>(null)
  const [errorCode, setErrorCode] = React.useState<ErrorCode | null>(null)
  // eslint-disable-next-line react/display-name
  const [errorComponent, setErrorComponent] = React.useState<ErrorComponentType | null>(null)
  const [type, setType] = React.useState<ErrorPageType | null>(null)
  const [resetKey, setResetKey] = React.useState<number>(Math.random())
  const location: Location = useLocation()
  const logger = useLogger()

  const setErrorData: ErrorPageContext["setError"] = ({ code = "unknown", error, component, type }) => {
    setErrorCode(code)
    setErrorComponent(component ?? defaultErrorPage(code))
    setType(type ?? "page")
    if (error) {
      setError(error)
    }
  }

  const clearError: ErrorPageContext["clearError"] = React.useCallback(() => {
    if (errorCode != null) {
      setResetKey(Math.random())
    }
    setErrorCode(null)
    setError(null)
    setErrorComponent(null)
  }, [errorCode])

  const inferError: ErrorPageContext["inferError"] = React.useCallback(
    (error, { strict, components = {}, type } = {}) => {
      logger.logException(error, undefined, { type })
      trackErrorPageView(error)
      components = { ...defaultErrorPages, ..._components, ...components }
      const _getComponent = (code: ErrorCode) =>
        components[code as keyof typeof components] ?? components.unknown ?? defaultErrorPage(code)
      const _getType = (code: ErrorCode) => type ?? defaultErrorType(code)
      strict = strict ?? _strict ?? true
      if (isAxiosError(error)) {
        if (error.message.includes("Network Error")) {
          if (navigator.onLine) {
            const code = error.response?.status ?? "unknown"
            setErrorData({ code, error, component: _getComponent(code), type: _getType(code) })
            return
          }
          const code = error.response?.status ?? "offline"
          setErrorData({ code, error, component: _getComponent(code), type: _getType(code) })
          return
        }
        const code = error.response?.status ?? "unknown"
        if (error.response) {
          setErrorData({ code, error, component: _getComponent(code), type: _getType(code) })
          return
        }
        setErrorData({ code: "unknown", error, type })
        return
      }
      if (error instanceof Error) {
        const code = "unknown"
        setErrorData({ code, error, component: _getComponent(code), type: _getType(code) })
        return
      }
      if ("code" in (error as object) || "message" in (error as object)) {
        const code = (error as any).code ?? "unknown"
        const message = (error as any).message ?? `Error ${code}`
        setErrorData({ code, error: new Error(message), component: _getComponent(code), type: _getType(code) })
        return
      }
      if (strict) {
        const code = "unknown"
        setErrorData({ code, error: new Error("Unknown error"), component: _getComponent(code), type: _getType(code) })
      }
    },
    [_components, _strict, logger, trackErrorPageView],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(clearError, [location.pathname])

  const value = React.useMemo(
    (): ErrorPageContext => ({
      error,
      errorCode,
      errorComponent,
      type,
      setError: setErrorData,
      clearError,
      inferError,
    }),
    [clearError, error, errorCode, errorComponent, inferError, type],
  )

  const content = React.useMemo(() => {
    if (errorCode) {
      if (type === "dialog") {
        return (
          <ErrorPageContext.Provider value={value}>
            {children}
            {errorComponent}
          </ErrorPageContext.Provider>
        )
      }
      return <ErrorPageContext.Provider value={value}>{errorComponent}</ErrorPageContext.Provider>
    }

    return <ErrorPageContext.Provider value={value}>{children}</ErrorPageContext.Provider>
  }, [children, errorCode, errorComponent, type, value])

  return (
    <ErrorReporter onCatch={inferError} fallback={errorComponent} key={resetKey}>
      {content}
    </ErrorReporter>
  )
}

export function useErrorPage(): ErrorPageContext {
  return React.useContext(ErrorPageContext)
}

function isAxiosError(error: unknown): error is AxiosError {
  return typeof error === "object" && (error as AxiosError).isAxiosError
}
