import { useEffect, useRef, useState } from "react"
import { CaseAPI, ExtendedCaseData } from "@planckdata/api"
import { Button, useCancelablePromise, useLogger } from "@planckdata/react-components"
import React from "react"
import { useErrorPage } from "../pages/ErrorPages"
import { Error404Page } from "../components/molecules/ErrorComponents"
import { useTranslation } from "../i18n"
import { Routes } from "../routes"
import { isAxiosError, getAxiosErrorDetails } from "@planckdata/typescript-utils"
import { Error429Page } from "../components/molecules/ErrorComponents/Error429"

export interface CaseFetchHook {
  caseData: ExtendedCaseData
  loading: boolean
  updateCaseData: <K extends keyof ExtendedCaseData>(key: K, data: React.SetStateAction<ExtendedCaseData[K]>) => void
}

type CaseKey = keyof ExtendedCaseData

export function useCaseFetch(
  caseId: string,
  keys: Array<keyof ExtendedCaseData>,
  timeoutInMilis?: number,
): CaseFetchHook {
  const MAX_RETRIES = 3
  const POLLING_INTERVAL = 3000
  const TIMEOUT = 15000

  const logger = useLogger()

  const [caseData, setCaseData] = useState<ExtendedCaseData>({} as ExtendedCaseData)
  const [loading, setLoading] = useState(true)
  // TODO useState - see TODO above useEffect lower in this hook
  // const missingKeys = useRef<Array<keyof ExtendedCaseData>>([...keys])
  // const [missingKeys, setMissingKeys] = useState<Array<keyof ExtendedCaseData>>([...keys])
  const errors = useRef(new Map<keyof ExtendedCaseData, number>())
  const { inferError } = useErrorPage()
  const timeoutTimerRef = useRef<number>()

  const startTimeoutTimer = React.useCallback(() => {
    timeoutTimerRef.current = window.setTimeout(() => {
      setCaseData((current) => {
        const temp: any = current
        keys.forEach((key) => {
          temp[key] = {
            ...temp[key],
            is_completed: true,
            error: temp[key]?.error ?? !temp[key]?.is_completed,
          }
        })
        return temp
      })
      if (timeoutInMilis != null) {
        // setMissingKeys([])
      }
    }, timeoutInMilis ?? TIMEOUT)
  }, [keys, timeoutInMilis])

  const updateCaseData = React.useCallback(
    <K extends keyof ExtendedCaseData>(key: K, data: React.SetStateAction<ExtendedCaseData[K]>) => {
      setCaseData((current) => {
        if (typeof data === "function") {
          return { ...current, [key]: data(current[key]) }
        }
        return { ...current, [key]: data }
      })
    },
    [],
  )

  const _fetchCase = React.useCallback(
    async (missingKeys: CaseKey[]) => {
      try {
        const response: ExtendedCaseData = await CaseAPI.getExtendedCaseData(caseId, missingKeys)
        const temp: ExtendedCaseData = { ...caseData }
        let newSectionCompleted = false
        let shouldStartTimeout = false
        missingKeys.forEach((k) => {
          const section = response[k]
          if (!section) {
            return
          }

          if (section.error) {
            const retryCount = (errors.current.get(k) ?? 0) + 1
            errors.current.set(k, retryCount)
            if (retryCount < MAX_RETRIES) {
              return
            }
          }

          temp[k] = section as any

          if (section.is_completed) {
            newSectionCompleted = true
            missingKeys = missingKeys.filter((_k) => k !== _k)
            if (k === "case") {
              shouldStartTimeout = true
            }
          }
        })

        if (newSectionCompleted || Object.keys(temp).length !== Object.keys(caseData).length) {
          setCaseData((current) => ({ ...current, ...temp }))
        }

        if (shouldStartTimeout) {
          startTimeoutTimer()
        }

        return { missingKeys: missingKeys }
      } catch (e) {
        const isIn400Range = isAxiosError(e) && e.response && e.response.status >= 400 && e.response.status < 500
        logger.logException(e, "Error fetching case", getAxiosErrorDetails(e), isIn400Range ? "warn" : "error")
        inferError(e as Error, {
          strict: false,
          components: {
            404: <FetchCaseError404Page />,
            429: <FetchCaseError429Page />,
          },
        })
        if (isIn400Range) {
          throw e
        }
        return { missingKeys: missingKeys }
        // do nothing, just retry
      }
    },
    [caseData, caseId, inferError, logger, startTimeoutTimer],
  )

  const [fetchCase, cancelFetch] = useCancelablePromise(_fetchCase, {}, [])

  useEffect(() => {
    let timerId: number
    let continueFetch = true

    const fetchUntilCompleted = async (missingKeys: CaseKey[]) => {
      if (missingKeys.length === 0 || !continueFetch) {
        setLoadingComplete()
      } else {
        try {
          const missingKeysAfter = (await fetchCase(missingKeys)).missingKeys
          if (missingKeysAfter.length > 0) {
            // try again in 3 seconds if there are more keys to fetch
            // will be skipped if error is caught
            timerId = window.setTimeout(() => fetchUntilCompleted(missingKeysAfter), POLLING_INTERVAL)
          } else {
            setLoadingComplete()
          }
        } catch (e) {
          if ((e as any).canceled) {
            return
          }
          // if error was re-thrown, it should be treated as fatal
          // otherwise, it would have been consumed by `fetchCase()`
          logger.logException(e, "Fatal error fetching case, not retrying")
          console.error("Fatal error fetching case, not retrying", e)
          continueFetch = false
          setLoadingComplete()
          cancelFetch()
        }
      }
    }

    const setLoadingComplete = () => {
      clearTimeout(timerId)
      clearTimeout(timeoutTimerRef.current)
      setLoading(false)
      cancelFetch()
      continueFetch = false
    }

    if (timeoutInMilis != null) {
      startTimeoutTimer()
    }
    fetchUntilCompleted(keys)

    return setLoadingComplete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    caseData,
    loading,
    updateCaseData,
  }
}

const FetchCaseError404Page: React.FC<any> = () => {
  const { t } = useTranslation()
  return (
    <Error404Page
      cta={({ ctx }) => (
        <Button href={Routes.MyBusinesses} onClick={ctx.clearError}>
          {t("single_business_page.errors.404.cta")}
        </Button>
      )}
    />
  )
}

const FetchCaseError429Page: React.FC<any> = () => {
  const { t } = useTranslation()
  return (
    <Error429Page
      cta={({ ctx }) => (
        <Button href={Routes.MyBusinesses} onClick={ctx.clearError}>
          {t("single_business_page.errors.429.cta")}
        </Button>
      )}
    />
  )
}
