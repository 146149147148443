import React from "react"
import { Redirect, RouteProps } from "react-router"
import { observer } from "mobx-react-lite"
import { LoadingContainer, SentryRoute } from "@planckdata/react-components"
import AppLoader from "../AppLoader"
import { PWAUser, useUser } from "user-context"
import { Routes } from "routes"
import { getRedirectTo } from "utils"

export interface PrivateRouteProps extends RouteProps {
  permissionCheck?(user: PWAUser): boolean
  permissionFailRedirectTo?: string
}

export const PrivateRoute: React.FC<PrivateRouteProps> = observer(
  ({ permissionCheck, permissionFailRedirectTo = Routes.Home, ...props }) => {
    const user = useUser()
    const condition = permissionCheck ? user.current && permissionCheck(user.current) : true
    const queryString = `returnTo=${getRedirectTo()}`

    const routeComponent = React.useMemo(() => {
      if (!user.isLoggedIn) {
        return <Redirect to={{ pathname: Routes.Login, search: queryString }} />
      }

      if (condition) {
        return <SentryRoute {...props} />
      } else {
        return <Redirect to={{ pathname: permissionFailRedirectTo }} />
      }
    }, [condition, permissionFailRedirectTo, props, user.isLoggedIn, queryString])

    return (
      <LoadingContainer
        loading={Boolean(!user) || Boolean(!user.firstLoggedIn) || user.isLoggingOut}
        loader={<AppLoader />}
      >
        {routeComponent}
      </LoadingContainer>
    )
  },
)

export default PrivateRoute
