import React, { Suspense, useState } from "react"
import { Switch } from "react-router-dom"
import { Routes } from "routes"
import { LoginForm, LoginFormMode, SentryRoute, makeStyles, useLogger } from "@planckdata/react-components"
import AuthLayout from "../../../components/layouts/AuthLayout"
import ClarityConsentDialog, { useClarityConsent } from "components/molecules/ClarityConsentDialog"
import { usePageView, useLogin } from "./router-hooks"
import AppLoader from "../AppLoader"
import MainLayoutRoutes from "./MainLayoutRoutes"
import PrivateRoute from "./PrivateRoute"
import { useQuotaFetch } from "utils/quota-utils"
import { FormType } from "pages/Password/UpdatePassword/models"
import { useUser } from "user-context"
import IdleChecker from "components/atoms/IdleChecker"

export interface AppRouterProps {}

const useStyles = makeStyles({
  updatePasswordFormContainer: {
    paddingLeft: 64,
    paddingRight: 64,
  },
})

const TermsAndConditions = React.lazy(() => import("pages/TermsAndConditions"))
const ForgotPasswordPage = React.lazy(() => import("pages/Password/ForgotPassword/ForgotPasswordPage"))
const UpdatePasswordPage = React.lazy(() => import("pages/Password/UpdatePassword/UpdatePasswordPage"))

const AppRouter: React.FC<AppRouterProps> = () => {
  const [loading, setLoading] = useState(true)
  const loginProps = useLogin({ loading, setLoading, showResetPassword: true })
  const classes = useStyles()
  const { dialogVisible } = useClarityConsent()
  const { current: user } = useUser()
  const logger = useLogger()
  usePageView()
  useQuotaFetch()

  React.useEffect(() => {
    if (user) {
      logger.setUser(user.username, {
        flags: Object.keys(user.flags).join(","),
        permissions: user.permissions.join(","),
        orgName: user.orgName,
        orgType: user.orgType,
        internal: user.internal,
        hasMappedInsights: user.hasMappedInsights,
        hasQuota: Boolean(user.hasQuota),
        locale: user.locale,
      })
    }
  }, [user, logger])

  return (
    <Suspense fallback={<AppLoader />}>
      <IdleChecker>
        <Switch>
          {/* Login routes - need different layout */}
          <SentryRoute
            path={Routes.Login}
            exact
            render={() => (
              <AuthLayout>
                <LoginForm {...loginProps} />
              </AuthLayout>
            )}
          />
          <SentryRoute
            path={Routes.Register}
            exact
            render={() => (
              <AuthLayout>
                <LoginForm {...loginProps} mode={LoginFormMode.Register} />
              </AuthLayout>
            )}
          />
          <SentryRoute
            path={Routes.ForgotPassword}
            render={() => (
              <AuthLayout>
                <ForgotPasswordPage />
              </AuthLayout>
            )}
          />
          <SentryRoute
            path={Routes.UpdatePassword}
            render={() => (
              <AuthLayout className={classes.updatePasswordFormContainer}>
                <UpdatePasswordPage formType={FormType.Update} />
              </AuthLayout>
            )}
          />
          <SentryRoute
            path={Routes.ActivateAccount}
            render={() => (
              <AuthLayout className={classes.updatePasswordFormContainer}>
                <UpdatePasswordPage formType={FormType.Activate} />
              </AuthLayout>
            )}
          />
          <PrivateRoute path={Routes.TermsAndConditions} render={() => <TermsAndConditions />} />
          {/* <SentryRoute
          path={Routes.ChangePassword}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} mode={LoginFormMode.ChangePassword} />
            </AuthLayout>
          )}
        /> */}
          {/* Main routes - need main layout */}
          <SentryRoute
            path="*"
            render={() => {
              return <MainLayoutRoutes />
            }}
          />
        </Switch>
      </IdleChecker>
      {dialogVisible && <ClarityConsentDialog />}
    </Suspense>
  )
}

export default AppRouter
