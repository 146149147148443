import { getHostingEnv } from "@planckdata/typescript-utils/env_utils"
import StorageKeys from "storageKeys"

// const isPlusUrl = /https?:\/\/plus(?:-beta)?(?:\.[a-z.]+)?\.planckdata\.com/i.test(location.href)
const isShowcaseUrl = /https?:\/\/showcase(?:-beta)?(?:\.[a-z.]+)?\.planckdata\.com/i.test(location.href)
const isPlusPlatform = !isShowcaseUrl || window.localStorage.getItem(StorageKeys.FORCE_PLUS) === "true"
const envName = getHostingEnv()

export const ENV = {
  IS_DEBUG: process.env.NODE_ENV === "development",
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!,
  MIXPANEL_API_KEY: process.env.REACT_APP_MIXPANEL_API_KEY!,
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL || "error",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN!,
  MIXPANEL_DEBUG: window.localStorage.getItem(StorageKeys.MIXPANEL_DEBUG) === "true",
  APP_VERSION: process.env.REACT_APP_VERSION,
  GOOGLE_PLACES_API_KEY: process.env.REACT_APP_GOOGLE_PLACES_API_KEY!,
  GOOGLE_STREET_API_KEY: process.env.REACT_APP_GOOGLE_STREET_API_KEY!,
  IS_PLUS_PLATFORM: isPlusPlatform,
  ENV_NAME: envName,
  APP_NAME: isPlusPlatform ? "Plus" : "Showcase",
}

if (envName === "production") {
  type A = Array<keyof typeof console>
  const c: any = console
  const noop = () => null
  const overrides: A = ["debug", "group", "groupCollapsed", "groupEnd", "info", "log", "table"]
  overrides.forEach((k) => (c[k] = noop))
}
