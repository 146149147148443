// TODO: move to typescript-utils

import { dumpQueryString } from "@planckdata/typescript-utils"
import { Routes } from "routes"
import { isAuthorized, Permission, PWAUser } from "../user-context"

export function getFullUrl(path: string): string {
  const port = location.port ? ":" + location.port : ""
  return `${window.location.protocol}//${window.location.hostname}${port}${path}`
}

export function hasProtocolPrefix(value: string): boolean {
  return /^(https?:\/\/|\/\/)/i.test(value)
}

export type OpenCaseParams = "business_name" | "address" | "dba" | "phone" | "website" | "linked_case_id"
export type OpenCaseQueryParams = Record<OpenCaseParams, string>

export function generateNewSubmissionRoute(prefill: Partial<OpenCaseQueryParams>): string {
  return Routes.NewSubmission + dumpQueryString(prefill, { removeEmpty: true })
}

export function getHomeRoute(user: PWAUser | null): string {
  if (isAuthorized(user, Permission.OpenCase)) {
    return Routes.Home
  }
  return Routes.MyBusinesses
}

/**
 * A function to create `returnTo` query param for post login
 */
export function getRedirectTo(): string {
  return encodeURIComponent(`${window.location.pathname}${window.location.search}`)
}
