import React from "react"
import { useTranslation } from "i18n"
import { Button, Row, Text, TextField, cn } from "@planckdata/components"

export interface SupportButtonFormProps {
  disabled?: boolean
  topic: string
  content: string
  expanded: boolean
  onSubmit: () => Promise<void>
  onClose: () => void
  onTopicChanged: (value: string) => void
  onContentChanged: (value: string) => void
}

export const TRANSITION_CLASSES = "transition-all duration-[400ms] ease-out"

const SupportButtonForm: React.FC<SupportButtonFormProps> = (props) => {
  const { disabled, topic, content, expanded, onTopicChanged, onContentChanged, onSubmit, onClose } = props
  const { t } = useTranslation()
  const [hasError, setHasError] = React.useState(false)
  const formValid = React.useMemo(() => content.trim().length > 0 && topic.trim().length > 0, [content, topic])

  React.useEffect(() => {
    if (expanded) {
      setHasError(false)
    }
  }, [expanded])

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (!formValid) {
        return
      }
      e.preventDefault()
      setHasError(false)
      try {
        await onSubmit()
      } catch (e) {
        setHasError(true)
      }
    },
    [formValid, onSubmit],
  )

  const handleSubjectChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onTopicChanged(e.target.value)
    },
    [onTopicChanged],
  )

  const handleMessageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onContentChanged(e.target.value)
    },
    [onContentChanged],
  )

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className={cn("flex flex-col gap-y-6", TRANSITION_CLASSES)} disabled={disabled}>
        <div
          className={cn(
            TRANSITION_CLASSES,
            "max-w-0 max-h-0 overflow-hidden",
            "flex flex-col gap-2 w-full",
            expanded && "max-w-sm max-h-[460px]",
          )}
        >
          <TextField
            label={t("support.popup.topic")}
            placeholder={t("support.popup.topic_placeholder")}
            value={topic}
            onChange={handleSubjectChange}
          />
          <TextField
            className="h-72"
            label={t("support.popup.content")}
            placeholder={t("support.popup.content_placeholder")}
            type="textarea"
            value={content}
            onChange={handleMessageChange}
          />
        </div>
        <div
          className={cn(
            TRANSITION_CLASSES,
            "flex items-center justify-between",
            "max-w-0 max-h-0 overflow-hidden opacity-0",
            "w-full",
            expanded && "max-w-sm max-h-[460px] opacity-100",
          )}
        >
          <div>
            <Text
              className={cn("leading-none opacity-0 transition-opacity", hasError && "opacity-100")}
              weight="semibold"
              variant="body-xs"
              color="red-100"
            >
              {t("support.popup.error_message")}
            </Text>
          </div>
          <Row gap={2}>
            <Button color="ghost" type="button" onClick={onClose}>
              {t("support.popup.close")}
            </Button>
            <Button color="primary" type="submit" disabled={!formValid}>
              {t("support.popup.send")}
            </Button>
          </Row>
        </div>
      </fieldset>
    </form>
  )
}

export default React.memo(SupportButtonForm)
