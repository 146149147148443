import React, { PropsWithChildren } from "react"
import { supportButtonController } from "components/atoms/SupportButton/SupportButtonController"
import { cn, Link, LinkProps } from "@planckdata/components"

export interface LinkStyleProps extends LinkProps {
  bold?: boolean
}

export const ContactUs: React.FC<PropsWithChildren<LinkStyleProps>> = ({ className, children, bold, ...rest }) => {
  return (
    <Link
      component="span"
      className={cn("cursor-pointer", bold && "font-bold", className)}
      underline="hover"
      onClick={() => supportButtonController.open()}
      {...rest}
    >
      {children}
    </Link>
  )
}

export interface DownloadTextProps extends Omit<LinkProps, "href">, LinkStyleProps {
  //
}

export const DownloadText: React.FC<DownloadTextProps> = ({ children, className, ...props }) => {
  return (
    <Link component="span" {...props} className={className}>
      {children}
    </Link>
  )
}
