import React from "react"
import { PostCaseRequest } from "@planckdata/api/case/models/post-case-request"
import { resolveValue } from "@planckdata/react-components"
import { useTranslation } from "../../../i18n"
import {
  useAddressValidator,
  useDbaValidator,
  usePhoneValidator,
  useWebsiteValidator,
  useCustomerPolicyIdValidator,
} from "../../../hooks/case-data-validators.hook"
import { useMap } from "react-use"

export type InputHighlights = boolean | (keyof PostCaseRequest)[]
export type PreparedPostCaseRequest = Required<Omit<PostCaseRequest, "additional_insights">> &
  Pick<PostCaseRequest, "additional_insights">

export function shouldHighlightInput(key: keyof PostCaseRequest, input: InputHighlights): boolean {
  if (typeof input === "boolean") {
    return input as boolean
  }
  return input.includes(key)
}

export function hasHighlights(input: InputHighlights): boolean {
  if (typeof input === "boolean") {
    return input as boolean
  }
  return input.length > 0
}

export function hasAdvancedHighlights(input: InputHighlights): boolean {
  if (typeof input === "boolean") {
    return input as boolean
  }
  return (
    input.filter((k) => (["dba", "phone", "website", "customerPolicyId"] as (keyof PostCaseRequest)[]).includes(k))
      .length > 1
  )
}

export function useInputHighlight(
  duration = 1500,
): readonly [InputHighlights, React.Dispatch<React.SetStateAction<InputHighlights>>] {
  const [inputsHighlighted, setInputsHighlighted] = React.useState<InputHighlights>(false)

  React.useEffect(() => {
    let id: number | undefined
    if (inputsHighlighted) {
      id = window.setTimeout(() => {
        setInputsHighlighted(false)
      }, duration)
    }

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [duration, inputsHighlighted, setInputsHighlighted])

  return [inputsHighlighted, setInputsHighlighted] as const
}

export interface OpenCaseFormHookOptions {
  data?: Partial<PostCaseRequest>
  additionalInsights?: Record<string, string[]>
}

type NonValidatingKey = "additional_info"
type NonEditableKeys = "linked_case_id" | "additional_insights" | NonValidatingKey
type EditableKeys = Exclude<keyof PostCaseRequest, NonEditableKeys>

export interface OpenCaseFormContext {
  readonly valid: Readonly<Record<"all" | EditableKeys, boolean>>
  readonly errors: Readonly<Record<EditableKeys, string | undefined>>
  readonly dirty: Readonly<Record<"all" | "any" | EditableKeys, boolean>>
  readonly set: Readonly<
    Record<keyof PostCaseRequest, React.Dispatch<React.SetStateAction<string>>> & {
      readonly all: React.Dispatch<React.SetStateAction<PostCaseRequest>>
      readonly key: (key: EditableKeys, value: React.SetStateAction<string>) => void
    }
  >
  readonly values: PreparedPostCaseRequest
  readonly hasChanges: boolean
  readonly initialData: PostCaseRequest
  readonly isAdvancedSearch: boolean
  readonly setGooglePlaceId: (googlePlaceId: string | undefined) => void
}

const noop = () => void 0

export const OpenCaseFormContext = React.createContext<OpenCaseFormContext>({
  values: {
    legal_name: "",
    address_free_text: "",
    dba: "",
    phone: "",
    website: "",
    customer_policy_id: "",
    linked_case_id: "",
    additional_info: {},
  },
  valid: {
    all: true,
    legal_name: true,
    address_free_text: true,
    dba: true,
    phone: true,
    website: true,
    customer_policy_id: true,
  },
  dirty: {
    all: false,
    any: false,
    legal_name: false,
    address_free_text: false,
    dba: false,
    phone: false,
    website: false,
    customer_policy_id: false,
  },
  errors: {
    legal_name: undefined,
    address_free_text: undefined,
    dba: undefined,
    phone: undefined,
    website: undefined,
    customer_policy_id: undefined,
  },
  set: {
    all: noop,
    key: noop,
    address_free_text: noop,
    legal_name: noop,
    linked_case_id: noop,
    dba: noop,
    phone: noop,
    website: noop,
    customer_policy_id: noop,
    additional_insights: noop,
    additional_info: noop,
  },
  hasChanges: false,
  initialData: {
    legal_name: "",
    address_free_text: "",
    dba: "",
    phone: "",
    website: "",
    customer_policy_id: "",
  },
  setGooglePlaceId: noop,
  isAdvancedSearch: false,
})

export function useOpenCaseFormContext(): OpenCaseFormContext {
  return React.useContext(OpenCaseFormContext)
}

export function useOpenCaseFormData({
  data: formData,
  additionalInsights,
}: OpenCaseFormHookOptions): OpenCaseFormContext {
  const { t } = useTranslation()

  const [initialData] = useMap<PreparedPostCaseRequest>({
    legal_name: formData?.legal_name ?? "",
    address_free_text: formData?.address_free_text ?? "",
    dba: formData?.dba ?? "",
    phone: formData?.phone ?? "",
    website: formData?.website ?? "",
    customer_policy_id: formData?.customer_policy_id ?? "",
    linked_case_id: formData?.linked_case_id ?? "",
    additional_insights: additionalInsights,
    additional_info: formData?.additional_info ?? {},
  })

  const [formValues, { setAll: setFormValues, set }] = useMap<PreparedPostCaseRequest>({ ...initialData })
  const [dirty, { set: setDirty }] = useMap<Required<Record<EditableKeys, boolean>>>({
    legal_name: false,
    address_free_text: false,
    dba: false,
    phone: false,
    website: false,
    customer_policy_id: false,
  })
  const dirtyMeta = React.useMemo(
    () => ({
      all: Object.values(dirty).every(Boolean),
      any: Object.values(dirty).some(Boolean),
    }),
    [dirty],
  )
  const { dba, phone, website, customer_policy_id, address_free_text, legal_name } = formValues
  const setAll = React.useCallback(
    (value: React.SetStateAction<PostCaseRequest>) => {
      const data = { ...formValues, ...resolveValue(value, formValues) }
      setFormValues(data)
    },
    [formValues, setFormValues],
  )

  const setField = React.useCallback(
    (key: keyof typeof formValues, value: React.SetStateAction<string>) => {
      set(key, resolveValue(value, formValues[key]! as string))
      setDirty(key as EditableKeys, true)
    },
    [formValues, set, setDirty],
  )

  const setLegalName = React.useCallback(
    (value: React.SetStateAction<string>) => setField("legal_name", value),
    [setField],
  )
  const setAddressFreeText = React.useCallback(
    (value: React.SetStateAction<string>) => setField("address_free_text", value),
    [setField],
  )
  const setLinkedCaseId = React.useCallback(
    (value: React.SetStateAction<string>) => setField("linked_case_id", value),
    [setField],
  )
  const setDba = React.useCallback((value: React.SetStateAction<string>) => setField("dba", value), [setField])
  const setPhone = React.useCallback((value: React.SetStateAction<string>) => setField("phone", value), [setField])
  const setWebsite = React.useCallback((value: React.SetStateAction<string>) => setField("website", value), [setField])
  const setCustomerPolicyId = React.useCallback(
    (value: React.SetStateAction<string>) => setField("customer_policy_id", value),
    [setField],
  )
  const setGooglePlaceId = (googlePlaceId: string | undefined) =>
    set("additional_info", { googlePlaceId: googlePlaceId } as any)
  const [legalNameValid, legalNameError] = useDbaValidator(t, formValues.legal_name)
  const [addressValid, addressError] = useAddressValidator(t, formValues.address_free_text)
  const [phoneValid, phoneError] = usePhoneValidator(t, phone)
  const [dbaValid, dbaError] = useDbaValidator(t, dba)
  const [websiteValid, websiteError] = useWebsiteValidator(t, website)
  const [customerPolicyIdValid, customerPolicyIdError] = useCustomerPolicyIdValidator(customer_policy_id)

  const hasChanges = React.useMemo(
    () => formValues.dba !== "" || formValues.phone !== "" || formValues.website !== "",
    [formValues],
  )

  const isAdvancedSearch = React.useMemo(
    () => formValues.dba !== "" || formValues.phone !== "" || formValues.website !== "",
    [formValues],
  )

  const isValid = React.useMemo((): boolean => {
    const baseValid = [legal_name.length && legalNameValid, address_free_text.length && addressValid].every(Boolean)
    const advancedValid = [phoneValid, dbaValid, websiteValid, customerPolicyIdValid].every(Boolean)
    return baseValid && advancedValid
  }, [
    addressValid,
    address_free_text.length,
    customerPolicyIdValid,
    dbaValid,
    legalNameValid,
    legal_name.length,
    phoneValid,
    websiteValid,
  ])

  return {
    valid: {
      all: isValid,
      legal_name: legalNameValid,
      address_free_text: addressValid,
      dba: dbaValid,
      phone: phoneValid,
      website: websiteValid,
      customer_policy_id: customerPolicyIdValid,
    },
    dirty: {
      ...dirtyMeta,
      ...dirty,
    },
    errors: {
      legal_name: legalNameError,
      address_free_text: addressError,
      dba: dbaError,
      phone: phoneError,
      website: websiteError,
      customer_policy_id: customerPolicyIdError,
    },
    set: {
      all: setAll,
      key: setField,
      address_free_text: setAddressFreeText,
      legal_name: setLegalName,
      linked_case_id: setLinkedCaseId,
      dba: setDba,
      phone: setPhone,
      website: setWebsite,
      customer_policy_id: setCustomerPolicyId,
      additional_insights: noop,
      additional_info: noop,
    },
    values: formValues,
    hasChanges,
    initialData,
    isAdvancedSearch,
    setGooglePlaceId,
  } as const
}
