import React from "react"
import { colors, alpha, Grid, PageContentBox, Typography, makeStyles } from "@planckdata/react-components"
import { Trans, useTranslation } from "i18n"
import LinkUploadFile from "../../atoms/LinkUploadFile"
import { ThinCrossIcon } from "@planckdata/react-components/components/icons"
import { BatchErrorBoxProps } from "./BatchStatusProps"

const useStyles = makeStyles({
  PageContentBox: {
    padding: 10,
    backgroundColor: `${alpha(colors.error, 0.04)}`,
    border: `2px solid ${alpha(colors.error, 0.24)}`,
    width: "auto",
    minWidth: 500,
  },
  CrossIcon: {
    fontSize: 10,
  },
  FileNameStatus: {
    color: colors.error,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  CrossContainer: {
    borderRadius: "50%",
    color: `${colors.error}`,
    background: `${alpha(colors.error, 0.2)}`,
    width: 15,
    height: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

export const BatchErrorBox: React.FC<BatchErrorBoxProps> = ({
  fileName,
  onFileSelected,
  acceptFileTypes,
  contentBoxProps,
  validateBatchErrorResponse,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageContentBox {...contentBoxProps} className={classes.PageContentBox}>
      <Grid data-testid="BatchUploadError" container spacing={1}>
        <Grid item xs={1} style={{ marginTop: "4px", flexBasis: "unset" }}>
          <div className={classes.CrossContainer}>
            <ThinCrossIcon className={classes.CrossIcon} />
          </div>
        </Grid>
        <Grid item xs={11}>
          <Typography size={12} weight={600} component="span">
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Trans
                  i18nKey="batch_status.error_text"
                  components={{ b: <span className={classes.FileNameStatus} /> }}
                  values={{ fileName, validateBatchErrorResponse }}
                />
              </Grid>
              <Grid item xs={2}>
                <LinkUploadFile
                  text={t("batch_status.try_again")}
                  onFileSelected={onFileSelected}
                  acceptFileTypes={acceptFileTypes}
                />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </PageContentBox>
  )
}

export default BatchErrorBox
