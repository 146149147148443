import React from "react"
import { useTranslation } from "i18n"
import { CheckIcon, ProcessingIcon, ErrorIcon } from "@planckdata/react-components/components/icons"
import { makeStyles, darken, alpha, GridSpacing, colors } from "@planckdata/react-components"
import { CaseStatus } from "@planckdata/api/case/models/case-status"
import { CaseRun } from "@planckdata/api/case/models"
import { TextIconProps } from "./shared"
import { IconLevel, TextIconContainer } from "components/atoms"

export interface CaseStatusIconTextProps extends TextIconProps {
  case: CaseRun
  spacing?: GridSpacing
}

const useStyles = makeStyles(() => ({
  completedIconContainer: {
    color: colors.success,
  },
  processingIconContainer: {
    color: darken(colors.warning, 0.2),
    "& circle": {
      fillOpacity: 0,
    },
  },
  errorIconContainer: {
    color: darken(colors.error, 0.2),
    "& circle, & rect": {
      fillOpacity: 0,
    },
  },
  disabledIconContainer: {
    color: `${colors.foregroundAltText}`,
  },
  greyIcon: {
    filter: "grayscale(100%)",
  },
}))

//Todo: implement all statuses in the same way
const CompletedTextIcon: React.FC<TextIconProps> = ({
  iconProps: { containerProps: iconContainerProps, ...iconProps } = {},
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer text={t("text_icons.status.completed")} {...props} role="status">
      <CheckIcon
        className={classes.completedIconContainer}
        containerProps={{
          background: alpha(colors.success, 0.2),
          color: colors.success,
          ...iconContainerProps,
        }}
        {...iconProps}
      />
    </TextIconContainer>
  )
}

const ProcessingTextIcon: React.FC<TextIconProps> = ({
  iconProps: { containerProps: iconContainerProps, ...iconProps } = {},
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer text={t("text_icons.status.processing")} level={IconLevel.Warning} {...props} role="status">
      <ProcessingIcon
        className={classes.processingIconContainer}
        containerProps={{
          background: alpha(colors.warning, 0.4),
          ...iconContainerProps,
        }}
        {...iconProps}
      />
    </TextIconContainer>
  )
}

const ErrorTextIcon: React.FC<TextIconProps> = ({
  iconProps: { containerProps: iconContainerProps, ...iconProps } = {},
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TextIconContainer text={t("text_icons.status.error")} level={IconLevel.Error} {...props} role="status">
      <ErrorIcon
        className={classes.errorIconContainer}
        containerProps={{
          background: alpha(colors.error, 0.4),
          ...iconContainerProps,
        }}
        {...iconProps}
      />
    </TextIconContainer>
  )
}

const CaseStatusIconText: React.FC<CaseStatusIconTextProps> = ({ case: caseData, spacing = 1, ...props }) => {
  const { t } = useTranslation()
  const { status } = caseData

  switch (status) {
    case CaseStatus.COMPLETED:
    case CaseStatus.FEEDBACK_PROVIDED:
      return <CompletedTextIcon spacing={spacing} data-testid="CompletedStatus" {...props} />
    case CaseStatus.ERROR:
      return (
        <ErrorTextIcon
          text={t("text_icons.status.case_error")}
          spacing={spacing}
          data-testid="ErrorStatus"
          {...props}
        />
      )
    case CaseStatus.PROCESSING:
      return <ProcessingTextIcon spacing={spacing} data-testid="ProcessingStatus" {...props} />
  }
}

export default React.memo(CaseStatusIconText)
