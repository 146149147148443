import React from "react"
import { cn, HtmlProps } from "@planckdata/components"
import { useMutation } from "@tanstack/react-query"
import { SupportAPI } from "@planckdata/api"
import { useTranslation } from "i18n"
import HelpCircle from "@planckdata/components/icons/HelpCircle"
import { useLogger } from "@planckdata/react-components"
import SupportButtonForm, { TRANSITION_CLASSES } from "./SupportButtonForm"
import SuccessState from "./SuccessState"
import { observer } from "mobx-react-lite"
import { supportButtonController } from "./SupportButtonController"

export type SupportButtonProps<T = Record<string, any>> = HtmlProps<"div"> & {
  topic?: string
  content?: string
  source: string
  additionalData?: T
}

/**
 * Button that floats on the bottom right of the screen and expands into a support email form
 */
export const SupportButton: React.FC<SupportButtonProps> = (props) => {
  const {
    className,
    topic: defaultTopic,
    content: defaultContent,
    source,
    additionalData,
    onClick: _onClick,
    ...rest
  } = props
  const logger = useLogger()
  const [topic, setTopic] = React.useState(defaultTopic ?? "")
  const [content, setContent] = React.useState(defaultContent ?? "")
  const { t } = useTranslation()
  const { isExpanded: expanded } = supportButtonController

  React.useEffect(() => {
    supportButtonController.mounted = true
    return () => {
      supportButtonController.mounted = false
    }
  }, [])

  const { mutateAsync: send, isLoading: isSending } = useMutation(
    async () => SupportAPI.sendRequest({ topic, content, source, additionalData }),
    {
      mutationKey: ["sendSupportRequest", topic, content, source, additionalData],
      retry: false,
      onError: (e) => logger.logException(e, "Failed to send support request"),
    },
  )

  const reset = React.useCallback(() => {
    setTopic(defaultTopic ?? "")
    setContent(defaultContent ?? "")
    supportButtonController.reset({ topic: defaultTopic, content: defaultContent })
  }, [defaultTopic, defaultContent])

  React.useEffect(() => {
    if (supportButtonController.topic) {
      setTopic(supportButtonController.topic)
    }
    if (supportButtonController.content) {
      setContent(supportButtonController.content)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportButtonController.topic, supportButtonController.content])

  const submit = React.useCallback(async () => {
    await send()
    supportButtonController.showSuccess()
    reset()
  }, [reset, send])

  const openPopup = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      _onClick?.(e)
      if (expanded) {
        return
      }
      supportButtonController.open()
    },
    [_onClick, expanded],
  )

  const handleNewMessage = React.useCallback(() => {
    supportButtonController.showForm()
    reset()
  }, [reset])

  const handleClose = React.useCallback(() => {
    supportButtonController.close()
    reset()
  }, [reset])

  React.useEffect(() => {
    if (defaultTopic) {
      setTopic(defaultTopic)
    }
    if (defaultContent) {
      setContent(defaultContent)
    }
  }, [defaultTopic, defaultContent])

  return (
    <div className={cn("fixed bottom-5 right-6 z-10", className)} onClick={openPopup} {...rest}>
      <div
        className={cn(
          TRANSITION_CLASSES,
          "h-fit-content overflow-hidden",
          "flex flex-col gap-0",
          "w-12",
          "drop-shadow-lg",
          expanded
            ? "w-[412px] gap-2 bg-white-light-purple p-3 rounded-3xl"
            : [
                "hover:ease-[cubic-bezier(0.44,_0.34,_0.15,_1.49)] ease-[cubic-bezier(0.78,_-0.43,_0.55,_0.65)]",
                "hover:w-60 hover:p-3 hover:bg-white hover:rounded-[32px] cursor-pointer",
              ],
        )}
      >
        <div className="items-center gap-2 inline-flex">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-indigo-400 shrink-0">
            <div className="text-2xl text-white">
              <HelpCircle />
            </div>
          </div>
          <div className={"flex-col justify-start items-start gap-0.5 inline-flex"}>
            <div className="text-neutral-700 text-lg font-semibold">{t("support.popup.title")}</div>
            <div
              className={cn(
                TRANSITION_CLASSES,
                "h-5 text-neutral-400 text-sm overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[152px]",
                expanded && "max-w-sm",
              )}
            >
              {t("support.popup.subtitle")}
            </div>
          </div>
        </div>
        <div className={cn("px-2 opacity-0", expanded && "opacity-100")}>
          <hr className="h-px" />
        </div>
        <div className={cn("h-0", TRANSITION_CLASSES, expanded && "h-[468px]")}>
          {supportButtonController.isForm && (
            <SupportButtonForm
              onSubmit={submit}
              onClose={handleClose}
              disabled={isSending}
              topic={topic}
              content={content}
              expanded={expanded}
              onTopicChanged={setTopic}
              onContentChanged={setContent}
            />
          )}
          {supportButtonController.isSuccess && <SuccessState onClose={handleClose} onNewMessage={handleNewMessage} />}
        </div>
      </div>
    </div>
  )
}

export default observer(SupportButton)
