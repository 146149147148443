import React from "react"
import { useUser } from "user-context"
import { ConfirmDialog } from "@planckdata/react-components"
import { Trans, useTranslation } from "i18n"

export interface OpenCasesConfirmDialog {
  onConfirm: () => void
  onCancel: () => void
  open: boolean
}

export const OpenCasesConfirmDialog: React.FC<OpenCasesConfirmDialog> = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation()
  const user = useUser()

  return (
    <ConfirmDialog
      dialogType="warning"
      open={open}
      onConfirm={onConfirm}
      onClose={onCancel}
      confirmText={t("open_cases_confirm_dialog.confirm_text")}
      cancelText={t("open_cases_confirm_dialog.cancel_text")}
      message={
        <Trans
          i18nKey="open_cases_confirm_dialog.message"
          values={{
            organization: user.current?.orgName,
          }}
        />
      }
    />
  )
}
