import React from "react"
import clsx from "clsx"
import { Fade, Grid, IconButton, Link, Logo, makeStyles } from "@planckdata/react-components"
import { PinIcon, UnpinIcon } from "@planckdata/react-components/components/icons"
import { useUser } from "user-context"
import { observer } from "mobx-react-lite"
import { getHomeRoute } from "utils"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: theme.spacing(3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  grid: {
    position: "relative",
  },
  gridExpanded: {
    textAlign: "center",
  },
  iconContainer: {
    position: "absolute",
    right: -10,
  },
}))

export interface SideMenuHeaderProps {
  isOpen: boolean
  isPinned: boolean
  drawerPinnedHandler?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

export const SideMenuHeader: React.FC<SideMenuHeaderProps> = observer(
  ({ isOpen, isPinned, drawerPinnedHandler, ...props }) => {
    const classes = useStyles()
    const user = useUser()
    const homeRoute = getHomeRoute(user.current)

    return (
      <div className={classes.toolbar} {...props}>
        <Grid
          container
          className={clsx(classes.grid, { [classes.gridExpanded]: !isOpen })}
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
        >
          {isOpen ? (
            <>
              <Link to={homeRoute}>
                <Logo />
              </Link>
              <div className={classes.iconContainer}>
                <Fade in>
                  <IconButton onClick={drawerPinnedHandler}>
                    {isPinned ? <PinIcon /> : <UnpinIcon fillOpacity={0.4} />}
                  </IconButton>
                </Fade>
              </div>
            </>
          ) : (
            <Grid item xs={12}>
              <Link to={homeRoute}>
                <Logo variant="mini" />
              </Link>
            </Grid>
          )}
        </Grid>
      </div>
    )
  },
)
export default SideMenuHeader
