import React from "react"
import { CustomComponentProps } from "@planckdata/react-components"
import { ReactComponent as Image } from "./images/error-404.svg"
import { CustomErrorDialogProps, CustomErrorPage, CustomErrorPageProps } from "../../../pages/ErrorPages/CustomError"

export interface Error404PageProps extends CustomComponentProps<Partial<CustomErrorPageProps>> {
  //
}

export interface Error404DialogProps extends CustomComponentProps<Partial<Omit<CustomErrorDialogProps, "open">>> {
  open: boolean
}

/** @see {@link CustomErrorPage} for props you may override */
export const Error404Page: React.FC<Error404PageProps> = (props) => {
  const { className, ...rest } = props

  return (
    <CustomErrorPage
      className={className}
      titleKey="error_pages.404.title"
      descriptionKey="error_pages.404.description"
      image={<Image />}
      {...rest}
    />
  )
}
