import React from "react"
import { alpha, colors, ElementComponentProps, makeStyles, PlanckIcons } from "@planckdata/react-components"
import clsx from "clsx"
import InsightsMappingTooltip from "../InsightsMappingTooltip"

export interface InsightMappingInfoIconProps
  extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "title"> {
  title: React.ReactNode
}

const useStyles = makeStyles(() => ({
  root: {
    //
  },
  icon: {
    fontSize: "8px",
    color: alpha(colors.foregroundAltText, 0.4),
  },
  circle: {
    height: "14px",
    width: "14px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: alpha(colors.foregroundAltText, 0.4),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

export const InsightMappingInfoIcon: React.FC<InsightMappingInfoIconProps> = ({ className, title, ...props }) => {
  const classes = useStyles(props)

  return (
    <InsightsMappingTooltip className={clsx(className, classes.root)} title={title!} placement="bottom-start">
      <span className={classes.circle}>
        <PlanckIcons.InformationIcon2
          className={classes.icon}
          containerProps={{
            background: "transparent",
            size: 16,
          }}
        />
      </span>
    </InsightsMappingTooltip>
  )
}

export default InsightMappingInfoIcon
