import { UserAvatar, makeStyles, EventHandler } from "@planckdata/react-components"
import { ParsedUser } from "@planckdata/storage"
import React from "react"
import { PWAUser } from "user-context"
import SideMenuItem from "./SideMenuItem"
import { ProfileBox } from "../ProfileBox"
import clsx from "clsx"

export interface SideMenuProfileBoxProps {
  user: ParsedUser<PWAUser>
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles({
  menu: {
    marginLeft: 52,
  },
  avatar: {
    marginRight: 12,
  },
  avatarDrawerClosed: {
    marginLeft: -8,
  },
  userName: {
    fontWeight: 600,
  },
})

const SideMenuProfileBox: React.FC<SideMenuProfileBoxProps> = ({ user, open, onClose }) => {
  const classes = useStyles()

  return (
    <ProfileBox
      classes={{ paper: classes.menu }}
      userMenuProps={{
        popoverProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          onClose,
        },
      }}
    >
      <SideMenuItem
        icon={
          <UserAvatar
            className={clsx(classes.avatar, { [classes.avatarDrawerClosed]: !open })}
            userName={user.username}
            color="primary"
            initialsCount={1}
          />
        }
        route="#user-menu"
        sideMenuOpen={open}
        text={<span className={classes.userName}>{user.username}</span>}
        textSecondary={user.internal ? user.orgName : undefined}
        noWrap
        onClick={EventHandler.prevent()}
      />
    </ProfileBox>
  )
}

export default React.memo(SideMenuProfileBox)
