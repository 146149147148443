import {
  CurrentUserResponse as BaseCurrentUserResponse,
  UserSettings,
  setAuthorizationToken,
  unsetAuthorizationToken,
} from "@planckdata/api"
import { setupUserContext } from "@planckdata/storage"
import { dispatchTrackEvent, initAnalyticsUser } from "./analytics"
import { sessionHelper } from "components/atoms/IdleChecker/SessionHelper"

export enum Permission {
  OpenCase = "OpenCase",
  OpenBatch = "OpenBatch",
  ShowInsightsMapping = "ShowInsightsMapping",
  ChangeInsightsMapping = "ChangeInsightsMapping",
  ViewPocDashboard = "ViewPocDashboard",
  ShowInsightConfidence = "ShowInsightConfidence",
  ShowSagaEvidence = "ShowSagaEvidence",
  AskMax = "AskMax",
  ShowAllInsightsMapping = "ShowAllInsightsMapping",
  ExportCases = "ExportCases",
  ExportDataDictionary = "ExportDataDictionary",
}

export function isAuthorized(user: PWAUser | null, permission: Permission): boolean {
  return user != null && user.permissions.includes(permission)
}

export interface PWAUserFlags {
  /**
   * Only show current user's email
   *
   * Used by:
   * ALLIANZ_UK_WITH_DE, ALLIANZ_POC, ALLIANZ_UK_PROD, TOMERDEV_TEST, ALLIANZ_UK_TESTING_BASIC_INFO, SAHAR_TEST
   */
  anonymousState: boolean

  isPilot: boolean

  /**
   * Filter cases for current user by default
   *
   * Used by:
   * ALLIANZ_POC
   */
  defaultFilterByUser: boolean

  /**
   * Use Single Business Page v2
   *
   * @deprecated Not mapped but required for compatibility
   */
  newSingleBusinessPage: boolean

  /**
   * Replace empty insight value (N/A) with translated value
   *
   * Used by:
   * ALLIANZ_POC
   */
  replaceNaText: boolean

  /**
   * Show privacy note when opening cases (Generate Insights page and Batch Upload page)
   *
   * Used by:
   * ALLIANZ_POC
   */
  showPrivacyNote: boolean

  /**
   * Disable batch upload
   *
   * @deprecated Use `OpenBatch` permission
   */
  disableBatchUpload: boolean

  /**
   * Enable additional insights in case creation
   */
  enableAdditionalInsights: boolean

  /** Whether usage of MS Clarity was consented (enabled) or not (disabled) */
  ClarityConsented?: boolean

  /**
   * Maximum number of questions per case
   */
  plusChatLimit?: number

  /**
   * Flag indicated if user can edit insights
   */
  insightsEdit?: boolean

  /**
   * Flag indicated if user can use plus platform
   */
  isPlusUser?: boolean

  /**
   * Whether to show naics in default layout in SBP
   */
  showNaicsInDefaultLayout?: boolean

  /**
   * Whether to show carrier scores in SBP
   */
  showCarrierScores?: boolean

  /**
   * Categories list key for found phrases component
   */
  foundPhrasesCategories?: string

  /**
   * Whether to hide risk level-related components such as MBP flags & filter, SBP gauge. `false` by
   * default, meaning user will see risk level.
   */
  hideRiskLevel?: boolean
}

export interface PWAUserSettings {
  /** Show old SBP version instead of new V2 */
  ShowSBPV1?: boolean
  /** Show the "switch to v1/v2" toggle */
  ShowSBPV1Toggle?: boolean
  /** Insights Mapping related prefs */
  InsightsMapping?: {
    /** Include the mapping status column when exporting insights mapping */
    ExportMappingColumn?: boolean
  }
  /** Whether usage of MS Clarity was consented (enabled) or not (disabled) */
  ClarityConsented?: boolean
}

export interface RiskFactor {
  name: string
  highRiskValue: string
  standardName: string
  displayName: string
}

export interface PWAUser extends Omit<CurrentUserResponse, "permissions"> {
  topInsights: string[]
  riskFactors: RiskFactor[] | null
  flags: Partial<PWAUserFlags>
  permissions: Array<Permission>
  userSettings: UserSettings<PWAUserSettings>
  isPilot: boolean
  hasMappedInsights: boolean
  hasQuota?: boolean
}

export interface CurrentUserResponse extends BaseCurrentUserResponse {
  topInsights: string[]
  riskFactors: RiskFactor[] | null
  flags: Partial<PWAUserFlags>
  hasMappedInsights: boolean
  hasQuota?: boolean
}

function mapUser(user: BaseCurrentUserResponse, response: CurrentUserResponse): PWAUser {
  const pwaUser: PWAUser = {
    ...user,
    riskFactors: response.riskFactors,
    flags: { ...response.flags },
    permissions: userPermissionToEnum(user.permissions),
    topInsights: response.topInsights,
    isPilot: !!response.flags.isPilot && !user.internal,
    hasMappedInsights: response.hasMappedInsights !== false,
    hasQuota: response.hasQuota,
  }

  return pwaUser
}

function userPermissionToEnum(permissions: Array<string>): Array<Permission> {
  return permissions.map((p) => stringToPermission(p)).filter(Boolean) as Array<Permission>
}

function stringToPermission(value: string): Permission | null {
  return Permission[value as keyof typeof Permission] || null
}

async function onTokenChanged(idToken: string | null, expiresIn?: number): Promise<void> {
  if (!idToken) {
    unsetAuthorizationToken()
    sessionHelper.onTokenExpired()
    return
  }

  setAuthorizationToken(idToken)
  if (expiresIn) {
    sessionHelper.onTokenUpdated(expiresIn)
  }
}

const { useUser, userStore, UserProvider, UserConsumer } = setupUserContext({
  storeConfig: { mapUser, dispatchTrackEvent, initAnalyticsUser, onTokenChanged },
})
export { useUser, userStore, UserProvider, UserConsumer }
