import React from "react"
import { DropdownOption, DropdownList, DropdownListProps, CustomComponentProps } from "@planckdata/react-components"
import { FilterButton, FilterButtonProps } from "../../atoms/FilterButton"

export function getDisplayOrValue(option: DropdownOption): string {
  return option.displayName ?? option.value
}

export interface DropdownInputProps extends CustomComponentProps<DropdownListProps> {
  label: string
  selectedLabel: (selected: Array<DropdownOption>) => string
  items: Array<DropdownOption>
  icon?: React.ReactElement<any>
  searchable?: boolean
  buttonProps?: Partial<FilterButtonProps>
  onChange: (values: Array<DropdownOption>) => void
}

export const DropdownInput: React.FC<DropdownInputProps> = ({
  icon,
  onChange,
  label,
  selectedLabel,
  buttonProps,
  ...props
}) => {
  const selected = props.items.filter((p) => p.selected)

  const count = selected.length
  const _label = getLabel()

  function getLabel() {
    if (count === 0) {
      return label
    }

    return selectedLabel(selected)
  }

  function handleSelectedChanged(values: Array<DropdownOption>) {
    const selectedValues = values.filter((p) => p.selected)
    onChange(selectedValues)
  }

  return (
    <DropdownList onChange={handleSelectedChanged} {...props}>
      <FilterButton {...buttonProps} filterSelected={count > 0} icon={icon}>
        {_label}
      </FilterButton>
    </DropdownList>
  )
}

export default DropdownInput
