/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CaseData } from "@planckdata/api"
import { AnalyticsAction, useTracking } from "@planckdata/react-components"
import capitalize from "lodash/capitalize"
import React from "react"
import { Options } from "react-tracking"
import { SortOrder } from "utils"
import { ENV } from "env"
import { AnswerCodeNames } from "utils/AnswerCodeNames"

/**
 * Analytics Events
 */
export default function useTrackEvents(
  trackingData?: Partial<AnalyticsAction> | undefined,
  options?: Partial<Options<AnalyticsAction>> | undefined,
) {
  const { trackEvent, Track } = useTracking(
    { ...trackingData, appName: ENV.APP_NAME, appVersion: ENV.APP_VERSION },
    options,
  )

  // TODO more encapsulation for the events
  const cls = React.useMemo(() => {
    /**
     * Analytics Events
     */
    return new (class TrackEvents {
      Track = Track

      /** Street View  events */
      streetView = {
        /**
         * Event Name: `Successful street view`
         */
        imageLoadSuccess: () => {
          trackEvent({ action: "Successful street view" })
        },

        /**
         * Event Name: `Failed street view`
         * */
        imageLoadFailed: () => {
          trackEvent({ action: "Failed street view" })
        },
      }

      /** Answer Code Warning events */
      answerCodeWarning = {
        /** Resubmit button/link clicked */
        resubmitClick(type: "button" | "text"): void {
          trackEvent({
            action: "Answer Code Warning - Resubmit clicked",
            "Clicked on": type,
          })
        },

        /** Event Name: `Answer Code Warning - Contact Support clicked` */
        contactSupportClick(): void {
          trackEvent({
            action: "Answer Code Warning - Contact Support clicked",
          })
        },

        /** See Results click */
        seeResultsClick(): void {
          trackEvent({
            action: "Answer Code Warning - See Results clicked",
            "Clicked on": "button",
            "Clicked from": "popup message",
          })
        },

        /** Close Pop-up */
        closePopup: (code: AnswerCodeNames, dontShowAgain: boolean): void => {
          trackEvent({
            action: "Answer Code Warning - Close Pop-up",
            "Answer Code": code,
            "Clicked from": "popup message",
            "Don't Show Again": booleanYesNo(dontShowAgain),
          })
        },

        /** Info collapse change */
        infoCollapseChange(collapsed: boolean): void {
          trackEvent({
            action: "Answer Code Warning - Info collapse change",
            "Collapse/Expand": collapsed ? "Collapse" : "Expand",
            "Clicked from": "in-page message",
          })
        },

        /** Map additional insights clicked */
        mapAdditionalInsightsClick(type: "button" | "text"): void {
          trackEvent({
            action: "Answer Code Warning - Map additional insights clicked",
            "Clicked on": type,
          })
        },

        reopenLinkClicked: () => {
          trackEvent({
            action: "Answer Code Warning - Re-open link clicked",
          })
        },
      }

      singleBusiness = {
        /** Event Name: `Single Business Page Toggle` */
        SBPToggle: (page: string) => {
          trackEvent({ action: "Single Business Page Toggle", page })
        },

        askAnything: {
          /** Event Name: `Export Clicked` */
          exportClicked: () => {
            trackEvent({ action: "Export Clicked" })
          },
          /** Event Name: `About Modal Open` */
          AboutOpen: () => {
            trackEvent({ action: "About Modal Open" })
          },
          /** Event Name: `Question asked` */
          questionAsked: (caseId: string, question: string) => {
            trackEvent({ action: "Question asked", caseId, question })
          },
          /** Event Name: `Question answered` */
          questionAnswered: (caseId: string, question: string, status: "success" | "failure", response?: any) => {
            trackEvent({ action: "Question answered", caseId, question, status, ...response })
          },
          /** Event Name: `Stop clicked` */
          stopClicked: () => {
            trackEvent({ action: "Stop clicked" })
          },
          /** Event Name: `Retry clicked` */
          retryClicked: () => {
            trackEvent({ action: "Retry clicked" })
          },
        },

        phraseFinder: {
          /** Event Name: `Domain Link Clicked` */
          domainLinkClicked: (url: string) => {
            trackEvent({ action: "Domain Link Clicked", url })
          },
          /** Event Name: `Link Clicked` */
          linkClicked: (url: string) => {
            trackEvent({ action: "Link Clicked", url })
          },
          /** Event Name: `Reset Filters` */
          resetFiltersClicked: () => {
            trackEvent({ action: "Reset Filters" })
          },
          /** Event Name: `Filter Clicked` */
          filterClicked: (phrase: string, selected: boolean) => {
            trackEvent({ action: "Filter Clicked", phrase, selected })
          },
          /** Event Name: `Request To Edit Opened` */
          requestToEditOpened: (source: "Chip" | "Title" | "Footnote") => {
            trackEvent({ action: "Request To Edit Opened", source })
          },
          /** Event Name: `Toggle Item` */
          toggleItem: (domain: string, state: "Open" | "Close") => {
            trackEvent({ action: "Toggle Item", domain, state })
          },
        },

        messages: {
          toggle: (title: string, isOpen: boolean, index: number, totalMessages: number) => {
            trackEvent({
              action: "Toggle Message",
              title,
              state: isOpen ? "Open" : "Close",
              index: index + 1,
              totalMessages,
            })
          },
        },

        /** Event Name: `Export Single` */
        export: (caseId: string, format: string, hideEmptyValues?: boolean) => {
          trackEvent({
            action: "Export Single",
            "Case Id": caseId,
            "Export Type": format,
            "Hide Empty Values": hideEmptyValues,
          })
        },

        seeAllRiskFactors: () => {
          trackEvent({ action: "Eligibility more details" })
        },

        seeAllReviews: () => {
          trackEvent({ action: "See all reviews" })
        },

        sidebarNavigation: (itemLabel: string) => {
          trackEvent({ action: "Sidebar navigation", item: itemLabel })
        },

        /** Event Name: `Main Website clicked` */
        mainWebsiteClicked: () => {
          trackEvent({ action: "Main Website clicked" })
        },

        /** Event Name: `${type} icon clicked` */
        socialIconClicked: (type: string) => {
          trackEvent({ action: `${capitalize(type)} icon clicked` })
        },

        /** Event Name: `Copy` */
        copyToClipboard: (term?: string) => {
          trackEvent({ action: "Copy", Term: term })
        },

        /** Event Name: `Search opened` */
        browserSearchOpened: () => {
          trackEvent({ action: "Search opened" })
        },

        /** Event Name: `See Why clicked` */
        toggleSeeWhy: (insightName: string, state: "Open" | "Close") => {
          trackEvent({ action: "See Why clicked", insightName, state })
        },

        linkClicked: (link: string, source: string, additionalInfo?: Record<string, any>) => {
          trackEvent({ action: "Link clicked", link, source, ...additionalInfo })
        },

        contractorLicenses: {
          toggleShowMore: (state: boolean) => {
            trackEvent({ action: `Contractor Licenses - Show ${state ? "More" : "Less"} clicked` })
          },

          clickLicenseSource: (license: any, url: string) => {
            trackEvent({ action: "Contractor Licenses - License Source clicked", license, "Source URL": url })
          },
        },
        images: {
          clickSource: (url: string, name: string) => {
            trackEvent({ action: "Click Source", "Source URL": url, "Source Name": name })
          },
        },
      }

      newSubmission = {
        /** Event Name: `Case Created Successfully` */
        caseCreatedSuccessfully: (address: string, usedGooglePlaceId: boolean) => {
          trackEvent({ action: "Case Created Successfully", address, usedGooglePlaceId })
        },
        /** Event Name: `Case Creation Error` */
        caseCreationError: (error: any, address: string, usedGooglePlaceId: boolean) => {
          trackEvent({ action: "Case Creation Error", reason: error?.toString(), address, usedGooglePlaceId })
        },
        /** Event Name: `Batch Cases Created Successfully` */
        batchCasesCreatedSuccessfully: () => {
          trackEvent({ action: "Batch Cases Created Successfully" })
        },
        /** Event Name: `Errors file download` */
        batchUploadErrorsFileDownload: (totalCases: number, validCases: number) => {
          trackEvent({
            action: "Errors file download",
            "Total Cases": totalCases,
            "Valid Cases": validCases,
            "Invalid Cases": totalCases - validCases,
          })
        },
      }

      /** Event Names: `Insight Mapped`, `Insight Unmapped`, `Insight Starred`, `Insight Unstarred` */
      trackInsightMapped(
        packageName: string | null,
        insightName: string,
        action: "map" | "star",
        value: boolean,
      ): void {
        trackEvent({
          action: "Insight " + capitalize((value ? "" : "un") + (action === "star" ? "starred" : "mapped")),
          package: packageName?.replace(/\s/g, ""),
          insight_name: insightName.replace(/\s/g, ""),
        })
      }

      /** Event Name: `Insights Mapping Exported` */
      trackInsightsMappingExported(mappingColumn: boolean, packageName: string | null): void {
        trackEvent({
          action: "Insights Mapping Exported",
          mappingStatus: mappingColumn ? "Include" : "Exclude",
          package: packageName,
        })
      }

      /** Event Names: `Include Insights Mapping Status`, `Exclude Insights Mapping Status` */
      trackToggleMappingStatus(value: boolean): void {
        trackEvent({ action: `${value ? "Include" : "Exclude"} Insights Mapping Status` })
      }

      /**
       * Event Name: `Walkthrough step`
       * @deprecated
       */
      trackWalkthroughStep(step: number): void {
        trackEvent({ action: "Walkthrough step", step })
      }

      /** Event Name: `Page View` */
      trackPageView(): void {
        trackEvent({ action: "Page View" })
      }

      /** Event Name: `Error Page View` */
      trackErrorPageView(err: any): void {
        const error = {
          message: err.message ?? "(empty message)",
          stack: err.stack ?? "(no stack trace)",
        }
        trackEvent({ action: "Error Page View", ...error })
      }

      /** Event Name: `Filter` */
      trackDashboardFiltersChanged(filters: Array<any>): void {
        trackEvent({ action: "Filter", filters })
      }

      myBusinesses = {
        /** Event Name: `Export Businesses` */
        exportBusinesses: (
          businessNameFilter?: string,
          filters?: Array<string>,
          numOfBusinesses?: number,
          sortByField?: string,
          sortOrder?: SortOrder,
        ) => {
          trackEvent({
            action: "Export Businesses",
            "Business Name": businessNameFilter,
            "No. of Businesses": numOfBusinesses,
            filters,
            sortBy: sortByField,
            sortOrder,
          })
        },

        /** Event Name: `My businesses error` */
        loadFailed: () => {
          trackEvent({ action: "My businesses error" })
        },

        /** Event Name: `Search Name` */
        searchByName: (businessName: string) => {
          trackEvent({ action: "Search Name", searchQuery: businessName })
        },

        /** Event Name: `Sort` */
        sort: (sortByField: string, sortOrder: SortOrder) => {
          trackEvent({ action: "Sort", sortBy: sortByField, sortOrder })
        },

        /** Event Name: `Filter` */
        filtersApplied: (filters: Array<string>) => {
          trackEvent({ action: "Filter", "Selected Filters": filters })
        },
      }

      /** Event Name: `New feedback` */
      trackNewFeedback(insightName: string, feedbackType: "Agree" | "Disagree", feedbackText?: string): void {
        trackEvent({
          action: "New feedback",
          "Insight name": insightName,
          "Agree/Disagree": feedbackType,
          "Feedback Text": feedbackText,
        })
      }

      /** Event Name: `Update feedback` */
      trackUpdateFeedback(insightName: string, feedbackType: "Agree" | "Disagree", feedbackText?: string): void {
        trackEvent({
          action: "Update feedback",
          "Insight name": insightName,
          "Agree/Disagree": feedbackType,
          "Feedback Text": feedbackText,
        })
      }

      /** Event Name: `No Web Presence` */
      trackNoWebPresence(): void {
        trackEvent({ action: "No Web Presence" })
      }

      /** Event Name: `Confidence Clicked` */
      trackInsightConfidenceClicked(
        insightName: string,
        insightValue: string,
        insightType: string,
        confidenceValue?: number,
      ): void {
        trackEvent({
          action: "Confidence Clicked",
          insightName,
          insightValue,
          insightType,
          confidence: confidenceValue ?? "Not Available",
        })
      }

      /** Event Name: `Confidence Hovered` */
      trackInsightConfidenceHovered(
        insightName: string,
        insightValue: string,
        insightType: string,
        confidenceValue?: number,
      ): void {
        trackEvent({
          action: "Confidence Hovered",
          insightName,
          insightValue,
          insightType,
          confidence: confidenceValue ?? "Not Available",
        })
      }

      /** Event Name: `Send via email` */
      trackSendBusinessViaEmail(mailInput: string): void {
        trackEvent({
          action: "Send via email",
          mailInput,
        })
      }
      /** Event Name: `Send via email failed` */
      trackSendBusinessViaEmailFailed(mailInput: string): void {
        trackEvent({
          action: "Send via email failed",
          mailInput,
        })
      }

      /** Event Name: `Confidence Tooltip Opened` */
      trackConfidenceTooltipOpened(): void {
        trackEvent({ action: "Confidence Tooltip Opened" })
      }

      /** Event Name: `Confidence Walkthrough Closed` */
      trackConfidenceWalkthroughClosed(): void {
        trackEvent({ action: "Confidence Walkthrough Closed" })
      }

      /** Event Name: `Confidence Walkthrough Opened` */
      trackConfidenceWalkthroughOpened(): void {
        trackEvent({ action: "Confidence Walkthrough Opened" })
      }

      /** Event Name: `Click on map` */
      trackMapClicked(): void {
        trackEvent({ action: "Click on map" })
      }

      /** Event Name: `SAGA Source Clicked` */
      trackSagaSourceClicked(sourceName: string): void {
        trackEvent({
          action: "SAGA Source Clicked",
          source_name: sourceName,
        })
      }

      /** Event Name: `Explore links` */
      trackExploreLinksClicked(): void {
        trackEvent({ action: "Explore links" })
      }

      /** Event Name: `Contact support` */
      trackContactFormSubmitted(): void {
        trackEvent({ action: "Contact support" })
      }

      /** Event Name: `T&C - Click Continue` */
      trackTermsAndConditionsAgree(): void {
        trackEvent({ action: "T&C - Click Continue" })
      }

      /** Event Name: `T&C - Click Back` */
      trackTermsAndConditionsDisagree(): void {
        trackEvent({ action: "T&C - Click Back" })
      }

      /** Event Name: `Batch upload` */
      trackBatchUploadSuccess(totalCases: number, invalidCases: number): void {
        trackEvent({
          action: "Batch upload",
          "Total Cases": totalCases,
          "Valid Cases": totalCases - invalidCases,
          "Invalid Cases": invalidCases,
        })
      }

      /** Event Name: `Batch upload error` */
      trackBatchUploadError(error: any): void {
        trackEvent({ action: "Batch upload error", Reason: error?.toString() })
      }

      trackTemplateDownload() {
        trackEvent({ action: "Template download" })
      }

      /** Event Name: `Advanced search` */
      trackAdvancedSearch(): void {
        trackEvent({ action: "Advanced search" })
      }

      /** Event Name: `Advanced search run` */
      trackAdvancedSearchRun(data: Record<"dba" | "phone" | "website", string>, existingCase?: CaseData): void {
        trackEvent({
          action: "Advanced search run",
          "Phone Field": data.phone,
          "Website Field": data.website,
          "DBA Field": data.dba,
          "Existing Case Id": existingCase?.case_id,
          "Existing Case Name": existingCase?.user_input.name,
          "Existing Case Address": existingCase?.user_input.address.description,
        })
      }

      /** Event Name: `Advanced search close` */
      trackAdvancedSearchClose(data: Record<"dba" | "phone" | "website", string>, existingCase?: CaseData): void {
        trackEvent({
          action: "Advanced search close",
          "Phone Field": data.phone,
          "Website Field": data.website,
          "DBA Field": data.dba,
          "Existing Case Id": existingCase?.case_id,
          "Existing Case Name": existingCase?.user_input.name,
          "Existing Case Address": existingCase?.user_input.address.description,
        })
      }

      /** Event Name: `Image expend` */
      trackImageExpend(): void {
        trackEvent({ action: "Image expend" })
      }
      /** Event Name: `Image navigation` */
      trackImageNavigation(): void {
        trackEvent({ action: "Image navigation" })
      }

      login = {
        /** Event Name: `Login form submitted` */
        loginFormSubmitted: () => {
          trackEvent({ action: "Login form submitted" })
        },

        /** Event Name: `Successful login` */
        successfulLogin: () => {
          trackEvent({ action: "Successful login" })
        },

        // /** Event Name: `Failed login` */
        // failedLogin: () => {
        //   trackEvent({ action: "Failed login" })
        // },

        /** Event Name: `Reset password clicked` */
        resetPasswordClicked: () => {
          trackEvent({ action: "Reset password clicked" })
        },
      }

      resetPassword = {
        /** Event Name: `Reset password form submitted` */
        resetPasswordSubmitted: () => {
          trackEvent({ action: "Reset password form submitted" })
        },

        /** Event Name: `Reset password failed` */
        resetPasswordFailed: () => {
          trackEvent({ action: "Reset password failed" })
        },

        /** Event Name: `Reset password try again clicked` */
        tryAgain: () => {
          trackEvent({ action: "Reset password try again clicked" })
        },

        /** Event Name: `Back to sign in` */
        backToLogin: () => {
          trackEvent({ action: "Back to sign in" })
        },

        /** Event Name: `Resend password email` */
        resendEmail: () => {
          trackEvent({ action: "Resend password email" })
        },

        /** Event Name: `Reset password submit clicked` */
        resetPasswordSubmitClicked: () => {
          trackEvent({ action: "Reset password submit clicked" })
        },
      }

      updatePassword = {
        /** Event Name: `Update password form submitted` */
        updatePasswordSubmit: () => {
          trackEvent({ action: "Update password form submitted" })
        },

        /** Event Name: `Password reset successful` */
        updatePasswordSucceeded: () => {
          trackEvent({ action: "Password reset successful" })
        },

        /** Event Name: `Update password failed` */
        updatePasswordFailed: (error: string) => {
          trackEvent({ action: "Password reset fail", error })
        },

        /** Event Name: `Update password try again clicked` */
        updatePasswordTryAgain: () => {
          trackEvent({ action: "Update password try again clicked" })
        },

        /** Event Name: `Back to sign in` */
        goToLogin: () => {
          trackEvent({ action: "Back to sign in" })
        },
      }

      export = {
        /** Event Name: `Download Exported File` */
        downloadExportedFile: (filename: string) => {
          trackEvent({ action: "Download Exported File", "File Name": filename })
        },

        /** Event Name: `Export limit message displayed` */
        exportLimit: (limit: number, total: number) => {
          trackEvent({ action: "Export limit message displayed", limit, total })
        },
        /** Event Name: `Export Button Clicked` */
        exportButtonClicked: () => {
          trackEvent({ action: "Export Button Clicked" })
        },
      }

      /** Event Name: `Logout` */
      trackLogout() {
        trackEvent({ action: "Logout" })
      }

      clarity = {
        consentGiven() {
          trackEvent({ action: "Clarity consent given" })
        },
        consentRejected() {
          trackEvent({ action: "Clarity consent rejected" })
        },
      }
    })()
  }, [Track, trackEvent])

  return cls
}

function booleanYesNo(value: boolean): "Yes" | "No" {
  return value ? "Yes" : "No"
}
