import React from "react"
import { i18nNamespace, Trans, useTranslation } from "i18n"
import { I18nNSContext } from "@planckdata/i18n"
import { colors, makeStyles } from "@planckdata/react-components"

export type QuotaHelpTextProps = {
  count: number
}
const useStyles = makeStyles({
  sendEmail: {
    color: colors.primary,
  },
})

export const QuotaHelpText: React.FC<QuotaHelpTextProps> = (props) => {
  const { count } = props
  const classes = useStyles(props)
  const { t } = useTranslation()
  const salesMail = t("sales_mail")

  return (
    <I18nNSContext.Provider value={i18nNamespace}>
      <Trans
        i18nKey="quota.help_tooltip"
        count={count}
        values={{ salesMail }}
        components={{
          sales: <a className={classes.sendEmail} target="_blank" rel="noreferrer" href={`mailto:${salesMail}}`} />,
        }}
      />
    </I18nNSContext.Provider>
  )
}

export default QuotaHelpText
