import { makeAutoObservable } from "mobx"
import { PhotosTab } from "./ImagesTabs"

export class ImagesTabStore {
  currentTab: PhotosTab | null = null
  containerRef: HTMLElement | null = null
  availableTabs: PhotosTab[] = ["map", "photos", "streetView"]

  constructor() {
    makeAutoObservable(this)
  }

  setAvailableTabs(tabs: PhotosTab[]): void {
    this.availableTabs = tabs
    if (this.availableTabs.length > 0 && this.currentTab === null) {
      this.currentTab = this.availableTabs[0]
    }
  }

  setCurrentTab(tab: PhotosTab): void {
    this.currentTab = tab
  }

  goToMap(): void {
    if (this.availableTabs.includes("map")) {
      this.currentTab = "map"
    } else {
      console.warn("Map tab is not available, this is a bug")
    }
  }

  scrollToMap(): void {
    this.containerRef?.scrollIntoView({ behavior: "smooth" })
    this.goToMap()
  }
}
