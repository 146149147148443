import { AnswerCode } from "@planckdata/api"
import React from "react"
import { AnswerCodeTextIconProps } from "./AnswerCodes"
import { answerCodeTextIcons, errorAnswerCodesPrioritization } from "./AnswerCodesMapping"

export interface AnswerCodeIndicatorTextIconProps {
  answerCodes: Array<AnswerCode>
}

function getRelevantPrioritizedAnswerCodesIndices(answerCodes: Array<AnswerCode>): Array<number> {
  const relevantPrioritizedAnswerCodesIndices = answerCodes
    .map((ac) => errorAnswerCodesPrioritization.indexOf(ac.code as typeof errorAnswerCodesPrioritization[number]))
    .filter((idx) => idx >= 0)
  relevantPrioritizedAnswerCodesIndices.sort()
  return relevantPrioritizedAnswerCodesIndices
}

export function shouldShowAnswerCodeIndicator(answerCodes: Array<AnswerCode>): boolean {
  const relevantIndices = getRelevantPrioritizedAnswerCodesIndices(answerCodes)
  return relevantIndices.length > 0
}

export const AnswerCodeIndicatorTextIcon: React.FC<AnswerCodeIndicatorTextIconProps> = ({ answerCodes }) => {
  const relevantPrioritizedAnswerCodesIndices = getRelevantPrioritizedAnswerCodesIndices(answerCodes)

  if (relevantPrioritizedAnswerCodesIndices.length === 0) return null

  const AnswerCodeElement: React.ComponentType<Partial<AnswerCodeTextIconProps>> = answerCodeTextIcons[
    errorAnswerCodesPrioritization[relevantPrioritizedAnswerCodesIndices[0]] as keyof typeof answerCodeTextIcons
  ] as React.ComponentType<Partial<AnswerCodeTextIconProps>>

  const elementProps = { ...AnswerCodeElement.defaultProps }
  elementProps.titleOnly = true

  return <AnswerCodeElement {...elementProps} />
}
