import React from "react"
import {
  AppBackground,
  Logo,
  PageContentBox,
  Grid,
  makeStyles,
  ElementComponentProps,
  Box,
} from "@planckdata/react-components"
import { I18nNSContext } from "@planckdata/i18n"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      padding: 15,
    },
    [theme.breakpoints.up("xs")]: {
      padding: 50,
    },
  },
  formContainer: {
    width: 520,
  },
  separator: {},
}))

export const AuthLayout: React.FC<ElementComponentProps<HTMLDivElement>> = ({ children, className, ...props }) => {
  const classes = useStyles(props)
  return (
    <I18nNSContext.Provider value="react-components">
      <AppBackground className={classes.root}>
        <PageContentBox className={clsx(classes.formContainer, className)}>
          <Grid container alignItems="center" justifyContent="center" direction="column">
            <Logo height={20} />
            <Box className={classes.separator} height={100} flexBasis={100} />
          </Grid>
          {children}
        </PageContentBox>
      </AppBackground>
    </I18nNSContext.Provider>
  )
}

export default AuthLayout
