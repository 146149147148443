import { NoWebPresenceIcon } from "@planckdata/react-components"
import { SvgIconProps } from "@planckdata/react-components/components/icons/Icon"
import React from "react"

export interface LimitedWebPresenceIconProps extends SvgIconProps {}

const LimitedWebPresenceIcon: React.FC<LimitedWebPresenceIconProps> = (props) => {
  return <NoWebPresenceIcon {...props} />
}

export default LimitedWebPresenceIcon
