import React, { PropsWithChildren } from "react"
import { Trans, useTranslation } from "i18n"
import { IconLevel, TextIconContainer, TextIconContainerProps } from "../../atoms/TextIconContainer"
import {
  BarChartMediumIcon,
  BarChartHighIcon,
  DbaIcon,
  DifferentAddressIcon,
  MailingAddressIcon,
  MismatchIcon,
  PartialAddressIcon,
  PermanentlyClosedIcon,
  PropertyOnlyIcon,
} from "@planckdata/react-components/components/icons"
import NoMatchIcon from "components/atoms/AnswerCodeIcons/NoMatchIcon"
import NoWebPresenceIcon from "components/atoms/AnswerCodeIcons/NoWebPresenceIcon"
import LimitedWebPresenceIcon from "components/atoms/AnswerCodeIcons/LimitedWebPresenceIcon"

export interface AnswerCodeTextIconProps extends TextIconContainerProps {
  level: IconLevel
  titleKey: string
  descriptionKey?: string
  titleOnly?: boolean
  applyTextColor?: boolean
  displayIcon?: boolean
}

// TODO move this to react-components?
type _PartialRequire<T, K extends keyof T = keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type AnswerCodeTextIconPropsHydrated = _PartialRequire<AnswerCodeTextIconProps, "level" | "titleKey">

export const AnswerCodeTextIcon: React.FC<PropsWithChildren<AnswerCodeTextIconProps>> = ({
  applyTextColor,
  displayIcon = true,
  descriptionKey,
  titleKey,
  titleOnly,
  level,
  children,
  ...textIconProps
}) => {
  const { t } = useTranslation()
  const text = !descriptionKey || titleOnly ? t(titleKey) : getTrans(descriptionKey)

  return (
    <TextIconContainer
      text={text}
      level={level}
      iconPlacing={!descriptionKey || titleOnly ? "center" : "flex-start"}
      applyTextColor={applyTextColor}
      displayIcon={displayIcon}
      {...textIconProps}
    >
      {children}
    </TextIconContainer>
  )

  function getTrans(descriptionKey: string) {
    return <Trans i18nKey={descriptionKey} components={{ b: <strong /> }} />
  }
}

export const HighWebPresenceShortTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartHighIcon />
    </AnswerCodeTextIcon>
  )
}
HighWebPresenceShortTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.high_web_presence.shortcut_title",
  level: IconLevel.Success,
}

export const LowWebPresenceShortTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartMediumIcon />
    </AnswerCodeTextIcon>
  )
}

LowWebPresenceShortTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.low_web_presence.shortcut_title",
  level: IconLevel.Warning,
}

export const NoWebPresenceShortTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <NoWebPresenceIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}
NoWebPresenceShortTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.no_web_presence.shortcut_title",
  level: IconLevel.Error,
}

export const HighWebPresenceValueTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartHighIcon />
    </AnswerCodeTextIcon>
  )
}
HighWebPresenceValueTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.high_web_presence.value_only",
  level: IconLevel.Success,
}

export const LowWebPresenceValueTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartMediumIcon />
    </AnswerCodeTextIcon>
  )
}

LowWebPresenceValueTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.low_web_presence.value_only",
  level: IconLevel.Warning,
}

export const NoWebPresenceValueTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <NoWebPresenceIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}
NoWebPresenceValueTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.no_web_presence.value_only",
  level: IconLevel.Error,
}

export const HighWebPresenceTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartHighIcon />
    </AnswerCodeTextIcon>
  )
}
HighWebPresenceTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.high_web_presence.title",
  descriptionKey: "text_icons.answer_codes.high_web_presence.description",
  level: IconLevel.Success,
}

export const LowWebPresenceTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <BarChartMediumIcon />
    </AnswerCodeTextIcon>
  )
}
LowWebPresenceTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.low_web_presence.title",
  descriptionKey: "text_icons.answer_codes.low_web_presence.description",
  level: IconLevel.Warning,
}

export const NoWebPresenceTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <NoWebPresenceIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}
NoWebPresenceTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.no_web_presence.title",
  descriptionKey: "text_icons.answer_codes.no_web_presence.description",
  level: IconLevel.Error,
}

export const PartialAddressTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <PartialAddressIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}
PartialAddressTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.partial_address.title",
  descriptionKey: "text_icons.answer_codes.partial_address.description",
  level: IconLevel.Error,
}

export const PermanentlyClosedTextIcon: React.FC<
  _PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">
> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <PermanentlyClosedIcon />
    </AnswerCodeTextIcon>
  )
}
PermanentlyClosedTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.permanently_closed.title",
  descriptionKey: "text_icons.answer_codes.permanently_closed.description",
  level: IconLevel.Error,
}

export const MailingAddressTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <MailingAddressIcon />
    </AnswerCodeTextIcon>
  )
}
MailingAddressTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.mailing_address.title",
  descriptionKey: "text_icons.answer_codes.mailing_address.description",
  level: IconLevel.Error,
}

export const MismatchTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <MismatchIcon />
    </AnswerCodeTextIcon>
  )
}
MismatchTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.mismatch_requested_segment.title",
  descriptionKey: "text_icons.answer_codes.mismatch_requested_segment.description",
  level: IconLevel.Error,
}

export const DifferentAddressMatchedTextIcon: React.FC<
  _PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">
> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <DifferentAddressIcon />
    </AnswerCodeTextIcon>
  )
}
DifferentAddressMatchedTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.different_address_matched.title",
  descriptionKey: "text_icons.answer_codes.different_address_matched.description",
  level: IconLevel.Error,
}

export const DbaTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <DbaIcon />
    </AnswerCodeTextIcon>
  )
}
DbaTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.dba_is_legal_name.title",
  descriptionKey: "text_icons.answer_codes.dba_is_legal_name.description",
  level: IconLevel.Warning,
}

export const PropertyOnlyTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <PropertyOnlyIcon style={{ color: "#D3B110" }} />
    </AnswerCodeTextIcon>
  )
}
PropertyOnlyTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.lob_only.title",
  descriptionKey: "text_icons.answer_codes.lob_only.description",
  level: IconLevel.Warning,
}

export const NoMatchTextIcon: React.FC<_PartialRequire<AnswerCodeTextIconPropsHydrated, "descriptionKey">> = ({
  ...props
}) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <NoMatchIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}
NoMatchTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.no_match.title",
  descriptionKey: "text_icons.answer_codes.no_match.description",
  level: IconLevel.Error,
}

export const LimitedWebPresenceTextIcon: React.FC<AnswerCodeTextIconPropsHydrated> = ({ ...props }) => {
  return (
    <AnswerCodeTextIcon {...(props as AnswerCodeTextIconProps)}>
      <LimitedWebPresenceIcon className="p-1" />
    </AnswerCodeTextIcon>
  )
}

LimitedWebPresenceTextIcon.defaultProps = {
  titleKey: "text_icons.answer_codes.limited_web_presence.title",
  level: IconLevel.Error,
}
