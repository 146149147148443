import React from "react"
import { useLocation, useParams } from "react-router"
import { ExtendedCaseData } from "@planckdata/api"
import { useCaseFetch } from "../../../hooks/CaseFetch.hooks"
import { getWebPresence } from "utils/case-utils"
import { AnswerCodeNames } from "utils/AnswerCodeNames"

export interface SingleBusinessPageContext {
  loading: boolean
  anyCompleted: boolean
  data: ExtendedCaseData | undefined
  areInsightsLoading: boolean
  sections: Array<keyof ExtendedCaseData>
  completedSections: Array<keyof ExtendedCaseData>
  caseId: string
  isNewCase: boolean
  webPresence: AnswerCodeNames
  updateCaseData<K extends keyof ExtendedCaseData>(key: K, value: React.SetStateAction<ExtendedCaseData[K]>): void
}

export interface SingleBusinessPageHookParamTypes {
  caseId: string
}

export interface SingleBusinessPageOptions {
  sections: Array<keyof ExtendedCaseData>
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const SingleBusinessPageContext = React.createContext<SingleBusinessPageContext>({
  loading: true,
  anyCompleted: false,
  data: undefined,
  areInsightsLoading: true,
  caseId: "",
  sections: [] as Array<keyof ExtendedCaseData>,
  completedSections: [] as Array<keyof ExtendedCaseData>,
  updateCaseData: noop,
  isNewCase: false,
  webPresence: AnswerCodeNames.high_web_presence,
})

/**
 * Hook for accessing the data about SBP from the context (must be provided elsewhere)
 *
 * @see {@link useSingleBusinessPage} for loading the data
 */
export const useSingleBusinessPageContext = (): SingleBusinessPageContext => {
  return React.useContext(SingleBusinessPageContext)
}

/**
 * Hook for loading the data for SBP. This actually performs the fetches and logic, so it should be used in the
 * top-level component only. For accessing the data, use {@link useSingleBusinessPageContext}
 * @param {SingleBusinessPageOptions} options The options for the hook
 * @returns {SingleBusinessPageContext} The data for the SBP page
 */
export function useSingleBusinessPage({ sections }: SingleBusinessPageOptions): SingleBusinessPageContext {
  const { caseId } = useParams<SingleBusinessPageHookParamTypes>()
  const { caseData, loading, updateCaseData } = useCaseFetch(caseId, sections)

  const areInsightsLoading =
    !caseData || !caseData.case || (!caseData.case.is_completed && caseData.case.insights.length === 0)

  const location = useLocation<{ isNewCase: boolean }>()
  const [isNewCase] = React.useState(location.state?.isNewCase)
  React.useEffect(() => {
    if (isNewCase) {
      window.history.replaceState({}, document.title)
    }
  }, [isNewCase])

  const anyCompleted = sections.map((key) => caseData[key]).filter((p) => !!p).length > 0
  const completedSections = sections.filter((key) => caseData[key]?.is_completed)
  const webPresence = getWebPresence(caseData?.case)

  return {
    isNewCase,
    anyCompleted,
    loading,
    areInsightsLoading,
    data: caseData,
    caseId,
    updateCaseData,
    completedSections,
    sections,
    webPresence,
  }
}
