import React from "react"
import { Trans, useTranslation } from "../../i18n"
import {
  CustomComponentProps,
  ElementComponentProps,
  Grid,
  makeStyles,
  PageContentBox,
  ResultDialogProps,
  Theme,
  Typography,
} from "@planckdata/react-components"
import clsx from "clsx"
import { ErrorPageContext } from "./ErrorPageContext"
import { supportButtonController } from "../../components/atoms/SupportButton/SupportButtonController"
import { Button } from "@planckdata/components"
import ErrorPageSupportButton from "../../components/molecules/ErrorPageSupportButton/ErrorPageSupportButton"

export interface CustomErrorPageProps
  extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "title">,
    CustomErrorProps,
    CustomErrorStyleProps {
  //
}
export interface CustomErrorDialogProps
  extends Omit<CustomComponentProps<ResultDialogProps, keyof typeof useStyles>, "title">,
    Omit<CustomErrorProps, "image">,
    CustomErrorStyleProps {
  //
}

export interface CustomErrorProps {
  /**
   * Image SVG source, use `import Image from 'image.svg'`, or custom components. Any string will be interpolated as
   * `src` for the `img` element, so if you need a string, wrap it in an element or a Fragment.
   */
  image: React.ReactNode
  /** title of the error. an `i18n` key that passes to `t`. */
  titleKey: string
  /** Description of the error. an `i18n` key that passes to `t`. */
  descriptionKey: string
  /**
   * CTA button. Use `ctx.clearError` to remove the error from the context when the CTA is pressed.
   *
   * Can be used both as a component or a render prop. Example:
   *
   * ```tsx
   * // As a render prop
   * <CustomErrorPage
   *  cta={({ ctx }) => (
   *   <Button onClick={ctx.clearError}>Go back</Button>
   * )} />
   *
   * // Pass a component directly
   * const GoBackButton: React.FC<{ ctx: ErrorPageContext }> = ({ ctx }) => (
   *   <Button onClick={ctx.clearError}>Go back</Button>
   * )
   * <CustomErrorPage cta={GoBackButton} />
   * ```
   */
  cta?: React.FC<{ ctx: ErrorPageContext }>
}

export interface CustomErrorStyleProps {
  contentWidth?: number | string
}

const useStyles = makeStyles<Theme, CustomErrorStyleProps>((theme) => ({
  root: {
    "&&": {
      margin: theme.spacing(4, "auto"),
    },
  },
  content: {
    maxWidth: ({ contentWidth = 608 }) => contentWidth,
    margin: "0 auto",
  },
  errorContent: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  imgWrapper: {
    margin: "0 auto",
    textAlign: "center",
  },
}))

/**
 * A generic component for error pages. See `image`, `titleKey`, `descriptionKey` and `cta` props for more details.
 *
 * There are also two pre-defined error pages: `Error404Page` and `Error500Page`, which you can use as base to extend
 * or override the default behavior.
 */
export const CustomErrorPage: React.FC<CustomErrorPageProps> = (props) => {
  const { className, image, titleKey, descriptionKey, cta, ...rest } = props
  const classes = useStyles(props)
  const { t } = useTranslation()
  const title = t(titleKey)

  return (
    <>
      <PageContentBox className={clsx(className, classes.root)} {...rest}>
        <Grid className={classes.content} container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <div className={classes.imgWrapper}>{typeof image === "string" ? <img src={image} /> : image}</div>
          </Grid>
          <Grid item xs={12}>
            <Typography size={32} weight={600} component="h1" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Trans
              i18nKey={descriptionKey}
              components={{
                p: <Typography size={18} align="center" component="p" className={classes.errorContent} />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ErrorPageContext.Consumer>
              {(ctx) => (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button color="ghost" onClick={() => supportButtonController.open()}>
                      {t("error_pages.common.contact_support")}
                    </Button>
                  </Grid>
                  {cta ? <Grid item>{cta({ ctx })}</Grid> : null}
                </Grid>
              )}
            </ErrorPageContext.Consumer>
          </Grid>
        </Grid>
      </PageContentBox>
      <ErrorPageSupportButton errorMessage={title} />
    </>
  )
}
