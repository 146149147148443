export const US_STATES = [
  {
    displayName: "Alabama (AL)",
    value: "Alabama",
    code: "AL",
  },
  {
    displayName: "Alaska (AK)",
    value: "Alaska",
    code: "AK",
  },
  {
    displayName: "American Samoa (AS)",
    value: "American Samoa",
    code: "AS",
  },
  {
    displayName: "Arizona (AZ)",
    value: "Arizona",
    code: "AZ",
  },
  {
    displayName: "Arkansas (AR)",
    value: "Arkansas",
    code: "AR",
  },
  {
    displayName: "California (CA)",
    value: "California",
    code: "CA",
  },
  {
    displayName: "Colorado (CO)",
    value: "Colorado",
    code: "CO",
  },
  {
    displayName: "Connecticut (CT)",
    value: "Connecticut",
    code: "CT",
  },
  {
    displayName: "Delaware (DE)",
    value: "Delaware",
    code: "DE",
  },
  {
    displayName: "District Of Columbia (DC)",
    value: "District of Columbia",
    code: "DC",
  },
  {
    displayName: "Florida (FL)",
    value: "Florida",
    code: "FL",
  },
  {
    displayName: "Georgia (GA)",
    value: "Georgia",
    code: "GA",
  },
  {
    displayName: "Guam (GU)",
    value: "Guam",
    code: "GU",
  },
  {
    displayName: "Hawaii (HI)",
    value: "Hawaii",
    code: "HI",
  },
  {
    displayName: "Idaho (ID)",
    value: "Idaho",
    code: "ID",
  },
  {
    displayName: "Illinois (IL)",
    value: "Illinois",
    code: "IL",
  },
  {
    displayName: "Indiana (IN)",
    value: "Indiana",
    code: "IN",
  },
  {
    displayName: "Iowa (IA)",
    value: "Iowa",
    code: "IA",
  },
  {
    displayName: "Kansas (KS)",
    value: "Kansas",
    code: "KS",
  },
  {
    displayName: "Kentucky (KY)",
    value: "Kentucky",
    code: "KY",
  },
  {
    displayName: "Louisiana (LA)",
    value: "Louisiana",
    code: "LA",
  },
  {
    displayName: "Maine (ME)",
    value: "Maine",
    code: "ME",
  },
  {
    displayName: "Maryland (MD)",
    value: "Maryland",
    code: "MD",
  },
  {
    displayName: "Massachusetts (MA)",
    value: "Massachusetts",
    code: "MA",
  },
  {
    displayName: "Michigan (MI)",
    value: "Michigan",
    code: "MI",
  },
  {
    displayName: "Minnesota (MN)",
    value: "Minnesota",
    code: "MN",
  },
  {
    displayName: "Mississippi (MS)",
    value: "Mississippi",
    code: "MS",
  },
  {
    displayName: "Missouri (MO)",
    value: "Missouri",
    code: "MO",
  },
  {
    displayName: "Montana (MT)",
    value: "Montana",
    code: "MT",
  },
  {
    displayName: "Nebraska (NE)",
    value: "Nebraska",
    code: "NE",
  },
  {
    displayName: "Nevada (NV)",
    value: "Nevada",
    code: "NV",
  },
  {
    displayName: "New Hampshire (NH)",
    value: "New Hampshire",
    code: "NH",
  },
  {
    displayName: "New Jersey (NJ)",
    value: "New jersey",
    code: "NJ",
  },
  {
    displayName: "New Mexico (NM)",
    value: "New Mexico",
    code: "NM",
  },
  {
    displayName: "New York (NY)",
    value: "New York",
    code: "NY",
  },
  {
    displayName: "North Carolina (NC)",
    value: "North Carolina",
    code: "NC",
  },
  {
    displayName: "North Dakota (ND)",
    value: "North Dakota",
    code: "ND",
  },
  {
    displayName: "Northern Mariana Is (MP)",
    value: "Northern Mariana Is",
    code: "MP",
  },
  {
    displayName: "Ohio (OH)",
    value: "Ohio",
    code: "OH",
  },
  {
    displayName: "Oklahoma (OK)",
    value: "Oklahoma",
    code: "OK",
  },
  {
    displayName: "Oregon (OR)",
    value: "Oregon",
    code: "OR",
  },
  {
    displayName: "Pennsylvania (PA)",
    value: "Pennsylvania",
    code: "PA",
  },
  {
    displayName: "Puerto Rico (PR)",
    value: "Puerto Rico",
    code: "PR",
  },
  {
    displayName: "Rhode Island (RI)",
    value: "Rhode Island",
    code: "RI",
  },
  {
    displayName: "South Carolina (SC)",
    value: "South Carolina",
    code: "SC",
  },
  {
    displayName: "South Dakota (SD)",
    value: "South Dakota",
    code: "SD",
  },
  {
    displayName: "Tennessee (TN)",
    value: "Tennessee",
    code: "TN",
  },
  {
    displayName: "Texas (TX)",
    value: "Texas",
    code: "TX",
  },
  {
    displayName: "Utah (UT)",
    value: "Utah",
    code: "UT",
  },
  {
    displayName: "Vermont (VT)",
    value: "Vermont",
    code: "VT",
  },
  {
    displayName: "Virginia (VA)",
    value: "Virginia",
    code: "VA",
  },
  {
    displayName: "Virgin Islands (VI)",
    value: "Virgin islands",
    code: "VI",
  },
  {
    displayName: "Washington (WA)",
    value: "Washington",
    code: "WA",
  },
  {
    displayName: "West Virginia (WV)",
    value: "West Virginia",
    code: "WV",
  },
  {
    displayName: "Wisconsin (WI)",
    value: "Wisconsin",
    code: "WI",
  },
  {
    displayName: "Wyoming (WY)",
    value: "Wyoming",
    code: "WY",
  },
]
