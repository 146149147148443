import React from "react"
import { alpha, colors, ElementComponentProps, FlagIcon, makeStyles, Theme } from "@planckdata/react-components"
import clsx from "clsx"
import { RiskLevel } from "@planckdata/api"
import { RED_TEXT_COLOR } from "components/colors"

export interface RiskFlagProps
  extends Omit<ElementComponentProps<HTMLDivElement, keyof typeof useStyles>, "value" | "ref">,
    RiskFlagStyleProps {
  //
}

export interface RiskFlagStyleProps {
  variant: "small" | "large" | "large-outlined"
  value?: RiskLevel | null
}

const useStyles = makeStyles<Theme, RiskFlagStyleProps>({
  root: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: 24,
    width: 24,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: ({ value }) => getRiskLevelColor(value),
  },
  icon: {
    color: ({ value }) => getRiskLevelColor(value),
  },
  iconContainer: {
    background: ({ value, variant }) =>
      variant === "large-outlined" ? undefined : alpha(getRiskLevelColor(value), 0.1),
  },
  iconContainerOutlined: {
    border: ({ value }) => `1px solid ${getRiskLevelBorderColor(value)}`,
    background: "transparent",
  },
})

const RiskFlag = React.forwardRef<HTMLDivElement, RiskFlagProps>(function _RiskFlag(props, ref) {
  const {
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
    variant,
    ...rest
  } = props

  const classes = useStyles(props)

  return (
    <div className={clsx(className, classes.root)} {...rest} ref={ref}>
      {variant === "large" || variant === "large-outlined" ? (
        <FlagIcon
          className={classes.icon}
          containerProps={{
            className: clsx(classes.iconContainer, {
              [classes.iconContainerOutlined]: variant === "large-outlined",
            }),
            size: 24,
          }}
          size={24}
        />
      ) : (
        <div className={classes.dot} />
      )}
    </div>
  )
})

const riskLevelColorMap = {
  [RiskLevel.none]: "#95999A",
  [RiskLevel.low]: colors.success,
  [RiskLevel.medium]: colors.warning,
  [RiskLevel.high]: RED_TEXT_COLOR,
}

const riskLevelBorderColorMap = {
  [RiskLevel.none]: "#E0DCDC",
  [RiskLevel.low]: colors.success,
  [RiskLevel.medium]: colors.warning,
  [RiskLevel.high]: RED_TEXT_COLOR,
}

export function getRiskLevelColor(riskLevel?: RiskLevel | null): string {
  return riskLevelColorMap[riskLevel ?? "none"]
}

export function getRiskLevelBorderColor(riskLevel?: RiskLevel | null): string {
  return riskLevelBorderColorMap[riskLevel ?? "none"]
}

/**
 * Displays a risk factor indicator.
 * Large variant displays a flag icon.
 * Small variant displays a colored dot.
 */
export default React.memo(RiskFlag)
