import { AppBackground, CircularProgress, ElementComponentProps, Grid, makeStyles } from "@planckdata/react-components"
import clsx from "clsx"
import React from "react"

const useStyles = makeStyles(() => ({
  root: {},
  grid: {
    height: "100vh",
  },
  loader: {},
}))

export const AppLoader: React.FC<ElementComponentProps<HTMLDivElement>> = ({ className, ...props }) => {
  const classes = useStyles(props)

  return (
    <AppBackground className={clsx(className, classes.root)} {...props}>
      <Grid container className={classes.grid} alignItems="center" justifyContent="center">
        <CircularProgress className={classes.loader} />
      </Grid>
    </AppBackground>
  )
}

export default AppLoader
