import React, { MouseEvent } from "react"
import { Button, colors, ButtonProps, makeStyles } from "@planckdata/react-components"
import { FileExportIcon } from "@planckdata/react-components/components/icons"
import { useTranslation } from "i18n"
import useTrackEvents from "hooks/track-events.hook"

const useStyles = makeStyles({
  FileExportIcon: {
    fontSize: 25,
    color: colors.primary,
  },
})

export interface ExportButtonProps extends ButtonProps {
  //
}

export const ExportButton: React.FC<ExportButtonProps> = ({ ...props }) => {
  const classes = useStyles()
  const { onClick, ...rest } = props
  const { t } = useTranslation()
  const { export: events } = useTrackEvents()

  const handleClick = React.useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      events.exportButtonClicked()
      onClick?.(e)
    },
    [events, onClick],
  )

  return (
    <Button
      variant="contained"
      color="default"
      startIcon={<FileExportIcon className={classes.FileExportIcon} />}
      data-testid="ExportCaseButton"
      onClick={handleClick}
      {...rest}
    >
      {props.children ?? t("export_dialog.title")}
    </Button>
  )
}
