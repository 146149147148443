import React, { lazy } from "react"
import { Switch } from "react-router-dom"
import clsx from "clsx"
import { batchRoutes, MyBusinessesRoutes, Routes } from "routes"
import MainLayout, { CONTENT_MAX_WIDTH } from "components/layouts/MainLayout"
import PrivateRoute from "./PrivateRoute"
import {
  Button,
  excludeIncludeValues,
  makeStyles,
  PageContentBox,
  SentryRoute,
  Theme,
} from "@planckdata/react-components"
import { i18nNamespace, useTranslation } from "i18n"
import AppLoader from "../AppLoader"
import { isAuthorized, Permission, useUser } from "user-context"
import { useHistory, useLocation } from "react-router"
import { useLocalStorage } from "@planckdata/typescript-utils/storage_utils"
import { dumpQueryString } from "@planckdata/typescript-utils/url_utils"
import StorageKeys, { StorageNS } from "storageKeys"
import { SBPContextProvider } from "pages/SingleBusiness/SBPOnboardingContext"
import { Error404Page } from "../../../components/molecules/ErrorComponents"

export interface PwaRoutesProps {}

const useStyles = makeStyles<Theme, { contentWidth?: number; maxWidth?: number }>((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(4),
    width: "100%",
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  limitWidth: {
    width: ({ contentWidth }) => contentWidth,
  },
}))

const NewSubmission = lazy(() => import("pages/NewSubmission/NewSubmission"))
const UploadBatchProcessPage = lazy(() => import("pages/UploadBatchProcess"))
const MyBusinessesPage = lazy(() => import("pages/MyBusinesses"))
const DownloadBatchPage = lazy(() => import("pages/DownloadBatch"))
const InsightsMapping = lazy(() => import("pages/InsightsMapping"))
const SingleBusinessPage = lazy(() => import("pages/SingleBusiness"))
const SupportPage = lazy(() => import("pages/SupportPage"))

const MainLayoutRoutes: React.FC<PwaRoutesProps> = () => {
  const classes = useStyles({ contentWidth: 1024, maxWidth: CONTENT_MAX_WIDTH })
  const { t } = useTranslation()
  const { current } = useUser()
  const history = useHistory()
  const location = useLocation()

  const devSkipInternalCheck = useLocalStorage<boolean>(StorageNS.DEV, StorageKeys.DEV_SKIP_INTERNAL_CHECK)
  const isInternal = React.useMemo(
    () => (devSkipInternalCheck.get() ? false : Boolean(current?.internal)),
    [current?.internal, devSkipInternalCheck],
  )

  const isPilotUser = Boolean(current?.isPilot)

  React.useEffect(() => {
    const termsApproved =
      current?.userSettings?.termsAndConditions && current.userSettings?.termsAndConditions.lastApprovedDate
    if (current && !isInternal && !termsApproved && !location.pathname.startsWith(Routes.TermsAndConditions)) {
      history.replace(Routes.TermsAndConditions + dumpQueryString({ returnTo: location.pathname + location.search }))
    }
  }, [history, location, current, devSkipInternalCheck, isInternal])

  return (
    <MainLayout ns={i18nNamespace}>
      <React.Suspense fallback={<AppLoader />}>
        <Switch>
          <PrivateRoute
            path={Routes.Batch}
            render={() => (
              <div className={clsx(classes.pageContainer, classes.limitWidth)}>
                <PageContentBox>
                  <UploadBatchProcessPage
                    routes={batchRoutes}
                    disabled={
                      isPilotUser ||
                      !isAuthorized(current, Permission.OpenBatch) ||
                      Boolean(current?.flags.disableBatchUpload)
                    }
                  />
                </PageContentBox>
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.SingleBusinessPage}
            render={() => (
              <div className="h-screen pt-2">
                <SBPContextProvider>
                  <SingleBusinessPage />
                </SBPContextProvider>
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.MyBusinesses}
            render={() => (
              <div className={classes.pageContainer}>
                <MyBusinessesPage
                  routes={MyBusinessesRoutes}
                  businessesScrollContainerProps={{ height: "calc(100vh - 300px)" }}
                />
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.DownloadExportedFile + "/:fileName"}
            render={() => (
              <div className={classes.pageContainer}>
                <DownloadBatchPage />
                <MyBusinessesPage
                  routes={MyBusinessesRoutes}
                  businessesScrollContainerProps={{ height: "calc(100vh - 300px)" }}
                />
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.InsightsMapping}
            permissionCheck={(user) =>
              excludeIncludeValues({
                values: user?.permissions,
                includeFor: ["ShowInsightsMapping"],
              })
            }
            render={() => (
              <div className={classes.pageContainer}>
                <InsightsMapping />
              </div>
            )}
          />
          <PrivateRoute
            path={Routes.Support}
            exact
            render={() => (
              <div className={clsx(classes.pageContainer)} style={{ width: 1200 }}>
                <PageContentBox width={1200}>
                  <SupportPage />
                </PageContentBox>
              </div>
            )}
          />
          {[Routes.NewSubmission, Routes.SingleBusiness, Routes.Home, Routes.GenerateInsights].map((path, index) => (
            <PrivateRoute
              path={path}
              key={index}
              exact
              render={() => (
                <div className={classes.pageContainer}>
                  <NewSubmission />
                </div>
              )}
            />
          ))}
          <SentryRoute
            path="*"
            render={() => (
              <Error404Page
                cta={({ ctx }) => (
                  <Button href={Routes.Home} onClick={ctx.clearError}>
                    {t("error_pages.404.cta")}
                  </Button>
                )}
              />
            )}
          />
        </Switch>
      </React.Suspense>
    </MainLayout>
  )
}

export default MainLayoutRoutes
