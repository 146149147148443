import React from "react"
import { OverflowTooltip } from "@planckdata/react-components"
import { useTranslation } from "i18n"
import { Insight, InsightValueType } from "@planckdata/api"
import { ClassMap, cn, HtmlProps, Link } from "@planckdata/components"
import { isEmptyInsightValue, isLikelyResult } from "utils"

export interface InsightValueProps extends HtmlProps<"span"> {
  value: string
  className?: string
  boldValue?: boolean
  insight: Insight
  onClick?: () => void
  tooltipProps?: Partial<React.ComponentProps<typeof OverflowTooltip>>
  classes?: ClassMap<"root" | "value">
}

export const InsightValue: React.FC<InsightValueProps> = (props) => {
  const { value, onClick, className, boldValue = true, insight, tooltipProps, classes = {}, ...rest } = props
  const { t } = useTranslation()

  const isBoolean = insight.expected_value_type === InsightValueType.BooleanAttribute
  const isLikely = isBoolean && isLikelyResult(insight)
  const displayValue = isLikely ? t("single_business_page.insights.likely_text", { value }) : value

  const isNA = isEmptyInsightValue(value)
  const isUrl = insight.expected_value_type === InsightValueType.URLAttribute && value && !isNA
  const Component = isUrl ? Link : "span"

  const handleLinkClick = React.useCallback(() => {
    if (isUrl) {
      onClick?.()
    }
  }, [isUrl, onClick])

  return (
    <OverflowTooltip className={cn("truncate block", classes.root)} title={displayValue} {...tooltipProps}>
      <Component
        className={cn(
          "flex w-full truncate",
          className,
          {
            "text-neutral-700 text-opacity-50 font-normal": isNA,
            "font-semibold": !isNA && boldValue,
          },
          classes.value,
        )}
        href={isUrl ? value : undefined}
        onClick={handleLinkClick}
        target="_blank"
        data-testid="InsightValue"
        {...(rest as any)}
      >
        {displayValue}
      </Component>
    </OverflowTooltip>
  )
}

export default React.memo(InsightValue)
