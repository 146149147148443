import React from "react"
import { Trans, useTranslation } from "i18n"
import { colors, alpha, Grid, PageContentBox, Typography, makeStyles } from "@planckdata/react-components"
import { CheckIcon } from "@planckdata/react-components/components/icons"
import LinkUploadFile from "../../atoms/LinkUploadFile"
import { BatchStatusBoxProps } from "./BatchStatusProps"

const useStyles = makeStyles({
  PageContentBox: {
    padding: 10,
    backgroundColor: `${alpha(colors.success, 0.04)}`,
    border: `2px solid ${alpha(colors.success, 0.24)}`,
    width: "auto",
    minWidth: 600,
  },
  FileNameStatus: {
    color: colors.success,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  CheckIcon: {
    verticalAlign: "middle",
    fontSize: 10,
    display: "inline-block",
    marginLeft: 2,
  },
  CheckContainer: {
    borderRadius: "50%",
    color: `${colors.success}`,
    background: `${alpha(colors.success, 0.2)}`,
    lineHeight: 0,
    paddingTop: 3,
    width: 15,
    height: 15,
  },
})

export const BatchSuccessBox: React.FC<BatchStatusBoxProps> = ({
  fileName,
  onFileSelected,
  acceptFileTypes,
  contentBoxProps,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PageContentBox {...contentBoxProps} className={classes.PageContentBox}>
      <Grid data-testid="BatchUploadSuccess" container spacing={1}>
        <Grid item xs={1} style={{ marginTop: "4px", flexBasis: "unset" }}>
          <div className={classes.CheckContainer}>
            <CheckIcon className={classes.CheckIcon} />
          </div>
        </Grid>
        <Grid item xs={11}>
          <Typography size={12} weight={600} component="span">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={10}>
                <Trans
                  i18nKey="batch_status.success_text"
                  values={{ fileName }}
                  components={{ b: <span className={classes.FileNameStatus} /> }}
                />
              </Grid>
              <Grid item xs={2}>
                <LinkUploadFile
                  text={t("batch_status.replace")}
                  onFileSelected={onFileSelected}
                  acceptFileTypes={acceptFileTypes}
                />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </PageContentBox>
  )
}

export default BatchSuccessBox
