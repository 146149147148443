export type AnswerCodeNames = keyof typeof AnswerCodeNames

/**
 * A mapping of all answer codes and their names for type inference.
 * Add new answer codes here to make them available to the rest of the app.
 */
export const AnswerCodeNames = {
  /**
   * The provided address is incomplete
   */
  partial_address: "partial_address",

  /**
   * The business was detected as permanently closed
   */
  permanently_closed: "permanently_closed",

  /**
   * The provided address is a mailing address and not a physical address
   */
  mailing_address: "mailing_address",

  /**
   * The submitted entity's business segment doesn't match the integrated segments
   */
  mismatch_requested_segment: "mismatch_requested_segment",

  /**
   * Didn't detect the relevant business at the submitted address
   */
  different_address_matched: "different_address_matched",

  /**
   * The provided DBA was detected as the business's legal name
   */
  dba_is_legal_name: "dba_is_legal_name",

  /**
   * The business has a low web presence
   */
  low_web_presence: "low_web_presence",

  /**
   * The business has no web presence
   */
  no_web_presence: "no_web_presence",

  high_web_presence: "high_web_presence",

  limited_web_presence: "limited_web_presence",
  no_match: "no_match",
  lob_only: "lob_only",
} as const
