import React from "react"
import { useIdleTimer, PresenceType } from "react-idle-timer"
import { sessionHelper } from "./SessionHelper"

const IDLE_TIMEOUT = 60_000 * 5

const IdleChecker: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    crossTab: true,
    onPresenceChange: onIdleChanged,
  })

  function onIdleChanged(presence: PresenceType) {
    if (presence.type === "idle") {
      sessionHelper.onUserIdle()
    } else {
      sessionHelper.onUserActive()
    }
  }

  return <>{children}</>
}

export default IdleChecker
