import { AnswerCodeNames } from "utils/AnswerCodeNames"
import {
  DbaTextIcon,
  DifferentAddressMatchedTextIcon,
  HighWebPresenceTextIcon,
  LimitedWebPresenceTextIcon,
  MailingAddressTextIcon,
  MismatchTextIcon,
  NoMatchTextIcon,
  NoWebPresenceTextIcon,
  PartialAddressTextIcon,
  PermanentlyClosedTextIcon,
  PropertyOnlyTextIcon,
} from "./AnswerCodes"

export type AnswerCodeIcon = React.FC<any>

export const answerCodeTextIcons: Record<AnswerCodeNames, React.FC> = {
  [AnswerCodeNames.low_web_presence]: LimitedWebPresenceTextIcon,
  [AnswerCodeNames.no_web_presence]: NoWebPresenceTextIcon,
  [AnswerCodeNames.high_web_presence]: HighWebPresenceTextIcon,
  [AnswerCodeNames.limited_web_presence]: LimitedWebPresenceTextIcon,
  [AnswerCodeNames.partial_address]: PartialAddressTextIcon,
  [AnswerCodeNames.permanently_closed]: PermanentlyClosedTextIcon,
  [AnswerCodeNames.mailing_address]: MailingAddressTextIcon,
  [AnswerCodeNames.mismatch_requested_segment]: MismatchTextIcon,
  [AnswerCodeNames.different_address_matched]: DifferentAddressMatchedTextIcon,
  [AnswerCodeNames.dba_is_legal_name]: DbaTextIcon,
  [AnswerCodeNames.no_match]: NoMatchTextIcon,
  [AnswerCodeNames.lob_only]: PropertyOnlyTextIcon,
} as const

export const errorAnswerCodesPrioritization = [
  AnswerCodeNames.permanently_closed,
  AnswerCodeNames.no_match,
  AnswerCodeNames.no_web_presence,
  AnswerCodeNames.limited_web_presence,
  AnswerCodeNames.lob_only,
  AnswerCodeNames.partial_address,
  AnswerCodeNames.mailing_address,
  AnswerCodeNames.low_web_presence,
] as const
