import { CustomComponentProps, PlanckIconProps, makeStyles } from "@planckdata/react-components"
import { TextIconContainerProps } from "components/atoms"

export interface TextIconProps extends CustomComponentProps<TextIconContainerProps> {
  value?: string | number
  iconProps?: PlanckIconProps
}

export const useStyles = makeStyles({
  iconSize: {
    width: 16,
    height: 16,
  },
  opacity4: {
    opacity: 0.4,
  },
  opacity7: {
    opacity: 0.7,
  },
})
