import { useEffect } from "react"
import { makeAutoObservable } from "mobx"
import { useQuery } from "@tanstack/react-query"
import { QuotaAPI } from "@planckdata/api"
import { useUser } from "user-context"

export interface QuotaResult {
  total: number | null
  used: number | null
  quotaReached: boolean | null
  isFetching: boolean
  refetch: () => void
  update: (data: Partial<QuotaResult>) => void
}

class QuotaStore implements QuotaResult {
  total: number | null = null
  used: number | null = null
  quotaReached: boolean | null = null
  isFetching = false
  refetch: () => void = () => void 0

  constructor() {
    makeAutoObservable(this)
  }

  update(data: Partial<QuotaResult>) {
    const { total, used, isFetching, refetch } = data
    this.total = total ?? this.total
    this.used = used ?? this.used
    this.isFetching = isFetching ?? this.isFetching
    this.quotaReached = this.used && this.total ? this.used >= this.total : null
    this.refetch = refetch ?? this.refetch
  }
}

const _quotaStore = new QuotaStore()

/**
 * Fetches the quota data and returns a QuotaStore instance, use this only when you need to fetch
 * the quota data from API explicitly. Otherwise, use `useQuota` hook to access the quota data.
 *
 * There is usually a fetch hook on the app router, so using this hook is not necessary in most
 * cases.
 */
export function useQuotaFetch(): QuotaStore {
  const { current: user } = useUser()
  const userKey = user?.username
  const { data, refetch, isFetching } = useQuery(["quota", userKey], QuotaAPI.getCasesQuota, { enabled: false })
  const { quota: total, used } = data ?? {}

  useEffect(() => {
    if (!_quotaStore.isFetching && userKey) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    _quotaStore.update({ total, used, isFetching, refetch })
  }, [total, used, isFetching, refetch])

  return _quotaStore
}

/**
 * Returns the quota data from the existing QuotaStore instance.
 *
 * There is usually a fetch hook on the app router, so using the `useQuotaFetch` hook is not
 * necessary in most cases. Use this hook to access the quota data instead.
 *
 * Don't forget to wrap your component in `observer()` from `mobx-react-lite` to make it reactive
 * when using this hook.
 */
export function useQuota(): QuotaResult {
  return _quotaStore
}
